import React from 'react';
import { DeleteWithConfirmButton, Edit, SaveButton, SimpleForm, Toolbar } from 'react-admin';
import AreaFormFields from './AreaFormFields';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomToolbar = (props: any) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteWithConfirmButton undoable={false} />
  </Toolbar>
);

const AreaEdit: React.FC = (props: unknown) => {
  return (
    <Edit
      {...props}
      undoable={false}
    >
      <SimpleForm autoComplete="off" toolbar={<CustomToolbar />}>
        <AreaFormFields />
      </SimpleForm>
    </Edit>
  );
};

export default AreaEdit;
