import { Button, Dialog, DialogTitle } from '@material-ui/core'
import React from 'react';
import { head } from 'lodash';
import {
  ListProps,
  useListContext,
  useTranslate,
} from 'react-admin';
import { SliderTabs } from './SliderTabs';
import { SlideEdit } from './SlideEdit';
import { SlideCreate } from './SlideCreate';
import { ISlideWithCategories, SlideType } from 'services/api/types';

export const MAX_PUBLISH_SLIDES_COUNT = 4;

const publishSlidesCount = (sl: ISlideWithCategories[] = []) => sl.filter(({ status }) => status === 'publish').length;

export const isEnoughCreateSlidesWithCategories = (sl: ISlideWithCategories[] = [], max: number) => publishSlidesCount(sl) >= max;
export const isEnoughEditSlidesWithCategories = (sl: ISlideWithCategories[] = [], max: number) => publishSlidesCount(sl) > max;

export const Banner: React.FC<ListProps> = (props) => {
  const [ editSlideId, setEditSlideId ] = React.useState<string | null>(null);
  const [ createModalIsOpen, setCreateModalIsOpen ] = React.useState<boolean>(false);
  const [ slideType, setSlideType ] = React.useState<SlideType>(SlideType.Desktop);
  const handleCloseEditDialog = React.useCallback(() => setEditSlideId(null), [ setEditSlideId ])
  const handleCloseCreateDialog = React.useCallback(() => setCreateModalIsOpen(false), [ setCreateModalIsOpen ])

  const ctx = useListContext();
  const translate = useTranslate();

  const { ids, data } = ctx;
  const id = head(ids);

  if (!id) return null;

  const slides: ISlideWithCategories[] = data[id][slideType];
  const editDialogTitle = `${slideType} ${translate('homePageBanner.slide.modal.edit.title')}`;
  const createSlideText = `${translate('homePageBanner.slide.modal.create')} ${slideType} ${publishSlidesCount(slides)}/${MAX_PUBLISH_SLIDES_COUNT}`;
  const disableCreation = isEnoughCreateSlidesWithCategories(slides, MAX_PUBLISH_SLIDES_COUNT);

  return (
    <>
      <Button
        onClick={() => setCreateModalIsOpen(true)}
        color="default"
        variant="contained"
        disabled={disableCreation}
        style={{ marginBottom: '24px' }}
      >
        {createSlideText}
      </Button>
      <SliderTabs slides={slides} slideType={slideType} setSlideType={setSlideType} setEditSlideId={setEditSlideId} />
      {editSlideId ? (
        <Dialog fullWidth maxWidth="md" onClose={handleCloseEditDialog} open>
          <DialogTitle>{editDialogTitle}</DialogTitle>
          <SlideEdit key={editSlideId} onDelete={handleCloseEditDialog} onSave={handleCloseEditDialog} {...props} slideType={slideType} id={editSlideId} />
        </Dialog>
      ) : null}
      {createModalIsOpen ? (
        <Dialog fullWidth maxWidth="md" onClose={handleCloseCreateDialog} open>
          <DialogTitle>{createSlideText}</DialogTitle>
          <SlideCreate {...props} onCreate={handleCloseCreateDialog} slideType={slideType} />
        </Dialog>
      ) : null}
    </>
  );
};