import React from 'react';
import { ListProps, ListBase } from 'react-admin';
import { Banner } from './Banner';


const HomePageBannerList: React.FC<ListProps> = (props) => {
  return (
    <ListBase {...props}>
      <Banner {...props} />
    </ListBase>
  )
}

export default HomePageBannerList;
