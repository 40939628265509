import { ApolloQueryResult } from 'apollo-client';
import { LoadType, QUERIES_BY_LOAD_TYPE } from 'services/api/queries';
import { CompletionType, ResponseByLoadType, UiAutocompleteEntry } from 'services/api/types';

export const getAddressListBuildObj = (params: any) => ({
  query: QUERIES_BY_LOAD_TYPE[LoadType.GetAddresses],
  variables: {
    text: params.filter.q || '',
    completionTypes: params.filter.completionTypes,
  },
  parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.GetAddresses]>) => {
    const data = res?.data?.addresses.map<UiAutocompleteEntry>(entry => ({
      ...entry,
      _id: entry.id,
      id: entry.docId
    })) || [];
    return { data, total: data.length };
  },
});

export const getProjectsListBuildObj = (params: any) => ({
  query: QUERIES_BY_LOAD_TYPE[LoadType.GetProjects],
  variables: {
    text: params?.filter?.q || '',
    completionTypes: params?.filter?.completionTypes || [ CompletionType.Project ],
  },
  parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.GetAddresses]>) => {
    const data = res?.data?.addresses || [];
    return { data, total: data.length };
  },
});
