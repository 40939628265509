import React, { useState } from 'react';
import {
  SelectInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  AutocompleteInput,
  ReferenceInput,
  minValue,
  maxValue,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import {
  createRequiredValidator,
} from 'utils/validators';
import { CompletionType, ReactAdminResource, WinnerRole, WinningCategories } from 'services/api/types';

const validateRequired = [createRequiredValidator()];


const MIN_DEALS = 0;
const MAX_DEALS = 9999;
const dealsValidators = [minValue(MIN_DEALS), maxValue(MAX_DEALS)];

export const roleChoices = [
  {id: WinnerRole.Agent, name: 'winners.role.agent'},
  {id: WinnerRole.Office, name: 'winners.role.office'},
];

export const categoryChoices = [
  {id: WinningCategories.Top10Gold, name: 'winners.category.top10Gold'},
  {id: WinningCategories.Top10, name: 'winners.category.top10'},
  {id: WinningCategories.DidNotWin, name: 'winners.category.didNotWin'},
  {id: WinningCategories.Top10GoldNotRegistered, name: 'winners.category.top10GoldNotRegistered'},
  {id: WinningCategories.Top10NotRegistered, name: 'winners.category.top10NotRegistered'},
  {id: WinningCategories.NeighbourhoodWinner, name: 'winners.category.neighbourhoodWinner'},
];

const addressesFilter = {
  completionTypes: [
    CompletionType.City,
    CompletionType.Neighbourhood,
  ]
};

interface WinnerFormFields {
  isEdit?: boolean;
}


const WinnerFormFields: React.FC<WinnerFormFields> = ({ isEdit }) => {
  const [showSoldHint, setShowSoldHint] = useState(false);
  const [showExclusiveHint, setShowExclusiveHint] = useState(false);
  const onSoldChange = () => isEdit && setShowSoldHint(true);
  const onExclusiveChange = () => isEdit && setShowExclusiveHint(true);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInput
          source="_id"
          label="winners.input.id"
          fullWidth
          disabled={isEdit}
          validate={validateRequired}
          resettable
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          source="name"
          label="winners.input.name"
          fullWidth
          validate={validateRequired}
          resettable
        />
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <SelectInput
            source="role"
            label="winners.input.role"
            choices={roleChoices}
            validate={validateRequired}
            disabled={isEdit}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <ArrayInput source="winningDocId" label="winners.input.winningDocId" validate={validateRequired}>
            <SimpleFormIterator>
              <SelectInput
                source="category"
                label="winners.input.category"
                choices={categoryChoices}
                validate={validateRequired}
                fullWidth
              />
              <ReferenceInput
                reference={ReactAdminResource.AddressWinners}
                resource={ReactAdminResource.AddressWinners}
                source="docId"
                label="winners.input.docIds"
                filter={addressesFilter}
                validate={validateRequired}
                fullWidth
              >
                <AutocompleteInput optionText="name"/>
              </ReferenceInput>
              <NumberInput
                source="soldCount"
                label="winners.input.soldCount"
                min={MIN_DEALS}
                max={MAX_DEALS}
                validate={dealsValidators}
                onChange={onSoldChange}
                helperText={showSoldHint ? 'winners.input.dealsHint' : null}
                fullWidth
              />
              <NumberInput
                source="exclusiveListingCount"
                label="winners.input.exclusiveListingCount"
                min={MIN_DEALS}
                max={MAX_DEALS}
                validate={dealsValidators}
                onChange={onExclusiveChange}
                helperText={showExclusiveHint ? 'winners.input.dealsHint' : null}
                fullWidth
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WinnerFormFields;
