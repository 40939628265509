import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import AdFormFields from './AdFormFields';


const AdEdit: React.FC = (props: unknown) => {
  return (
    <Edit
      {...props}
      undoable={false}
    >
      <SimpleForm autoComplete="off">
        <AdFormFields />
      </SimpleForm>
    </Edit>
  );
};

export default AdEdit;
