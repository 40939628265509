import React, { useState } from 'react';
import {
  Edit,
  SimpleForm,
  useMutation,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { ReactAdminResource } from 'services/api';
import DealFormFields from './DealFormFields';
import { DEAL_EXISTS_ERROR } from 'components/deals/helpers';
import DealAlreadyExistsDialog from 'components/deals/DealAlreadyExistsDialog';

const DealEdit: React.FC = (props: any) => {
  const [ isOpenDialog, setIsOpenDialog ] = useState(false);

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [ mutate ] = useMutation();

  const onClose = () => {
    redirect(`/${ReactAdminResource.Deals}`);
    refresh();
  };

  const onSuccess = (response: any) => {
    if (response.data.__typename === DEAL_EXISTS_ERROR) {
      setIsOpenDialog(true);
      return;
    }

    setIsOpenDialog(false);
    notify('deals.edit')
    onClose();
  };

  const onEdit = async (values?: any) => {
    try {
      await mutate({
        type: 'update',
        resource: ReactAdminResource.Deals,
        payload: { id: props.id,  data: values},
      }, {
        returnPromise: true,
        onSuccess,
      });
    } catch (error: any) {
      notify(error.message);
      onClose();
    }
  };

  const save = (values: any) => onEdit(values);

  return (
    <Grid container item xs={12} md={8}>
      <Edit {...props}>
        <SimpleForm autoComplete="off" save={save}>
          <DealFormFields isEdit />
        </SimpleForm>
      </Edit>
      {isOpenDialog && <DealAlreadyExistsDialog onClose={onClose} />}
    </Grid>
  );
};

export default DealEdit;
