import React from 'react';
import {
  TextInput,
  ImageInput,
  ImageField,
  SimpleForm,
  SelectInput,
  NumberInput,
} from 'react-admin';
import { SliderStatus, SlideType } from 'services/api/types';
import { createRequiredValidator, createMaxLength } from 'utils/validators';

const max40Length = createMaxLength(40);
const max28Length = createMaxLength(28);
const max30Length = createMaxLength(30);
const required = createRequiredValidator();
const validateRequired = [ required ];

const validateMobileMarketingText = [ max40Length, required ];
const validateMobileNavigationText = [ max28Length, required ];
const validateDesktopMarketingText = [ max40Length ];
const validateDesktopNavigationText = [ max30Length ];

const statusOptions: Array<{ id: SliderStatus; name: string }> = [
  { id: 'publish', name: 'Publish' },
  { id: 'draft', name: 'Draft' },
];

interface SlideFormProps {
  slideType: SlideType;
  initialId?: string;
}

export const SlideForm: React.FC<SlideFormProps> = ({ slideType, initialId, ...props }) => {
  const isMobile = slideType === SlideType.Mobile;

  const marketingTextValidation = isMobile ? validateMobileMarketingText : validateDesktopMarketingText;
  const navigationTextValidation = isMobile ? validateMobileNavigationText : validateDesktopNavigationText;
  const asideImageValidation = isMobile ? validateRequired : undefined;

  return (
    <SimpleForm {...props} mutationMode="optimistic">
      <TextInput
        source="id"
        label="homePageBanner.slide.id"
        initialValue={initialId}
        fullWidth
        disabled
        multiline
      />
      <TextInput
        source="slideType"
        label="homePageBanner.slide.type"
        initialValue={slideType}
        fullWidth
        disabled
        multiline
      />
      <SelectInput source="status" validate={validateRequired} allowEmpty={false} choices={statusOptions} />
      <TextInput
        source="marketingText"
        label="homePageBanner.slide.marketingText"
        fullWidth
        multiline
        validate={marketingTextValidation}
        resettable
      />
      <TextInput
        multiline
        source="navigation.text"
        fullWidth
        validate={navigationTextValidation}
        resettable
        label="homePageBanner.slide.navigation.text"
      />
      <TextInput
        multiline
        source="navigation.link"
        fullWidth
        resettable
        label="homePageBanner.slide.navigation.link"
      />
      <TextInput
        multiline
        source="aside.link"
        fullWidth
        resettable
        label="homePageBanner.slide.aside.link"
      />
      <ImageInput validate={asideImageValidation} source="aside.file" label="homePageBanner.slide.aside.image" accept="image/*">
        <ImageField source="url" title="title" />
      </ImageInput>
      {isMobile ? null : (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <NumberInput label="homePageBanner.slide.aside.imageCssProps.maxWidth" source="aside.imageCssProps.maxWidth" step={1} />
          <NumberInput label="homePageBanner.slide.aside.imageCssProps.transformX" source="aside.imageCssProps.transformX" step={1} />
          <NumberInput label="homePageBanner.slide.aside.imageCssProps.transformY" source="aside.imageCssProps.transformY" step={1} />
        </div>
      )}
    </SimpleForm>
  );
}

SlideForm.defaultProps = {
  initialId: undefined,
};
