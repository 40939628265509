import React from 'react';
import {
  List,
  Datagrid,
  EditButton,
  TextField,
  NumberField,
  FunctionField,
  ListProps,
  BulkExportButton,
  BulkDeleteWithConfirmButton,
  DeleteWithConfirmButton,
  Filter,
  TextInput,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { UiArea } from 'services/api/types';


const PostFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = (props: any) => (
  <>
    <BulkExportButton />
    <BulkDeleteWithConfirmButton {...props} />
  </>
);

const AreasList: React.FC<ListProps> = props => {
  return (
    <List
      filters={<PostFilter />}
      {...props}
      bulkActionButtons={<PostBulkActionButtons />}
      perPage={25}
    >
      <Datagrid
        optimized
      >
        <NumberField
          source="areaId"
          label="areas.column.id"
        />
        <TextField
          source="areaName"
          label="areas.column.name"
        />
        <FunctionField<UiArea>
          label="areas.column.relevantDocIds"
          sortable={false}
          render={record => {
            return (
              <div>
                {record?.relevantDocs?.map(doc => (
                  <Chip key={doc.docId} label={doc.name} />
                ))}
              </div>
            );
          }}
        />
        <EditButton />
        <DeleteWithConfirmButton undoable={false} />
      </Datagrid>
    </List>
  );
};

export default AreasList;
