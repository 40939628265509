import React from 'react';
import {
  TextInput,
  ImageInput,
  ImageField,
  SimpleForm,
} from 'react-admin';
import { createRequiredValidator } from 'utils/validators';


const required = createRequiredValidator();
const validateRequired = [ required ];

export const ProjectLogoForm: React.FC<any> = (props) => {
  const { isEdit, ...rest } = props;

  return (
    <SimpleForm {...rest} mutationMode="optimistic">
      <TextInput
        disabled={isEdit}
        source="id"
        label="projectLogoOnMap.input.id"
        validate={validateRequired}
        fullWidth
        multiline
      />
      <ImageInput validate={validateRequired} source="logo" label="projectLogoOnMap.input.image" accept="image/*">
        <ImageField source="url" title="title" />
      </ImageInput>
    </SimpleForm>
  );
}
