import React from 'react';
import {
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import {
  createRequiredValidator,
} from 'utils/validators';
import { CompletionType, ReactAdminResource } from 'services/api/types';

const validateRequired = [ createRequiredValidator() ];

const addressesFilter = {
  completionTypes: [ CompletionType.City ],
};


const AdFormFields: React.FC = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInput
          source="areaName"
          label="areas.input.name"
          fullWidth
          validate={validateRequired}
          resettable
        />
      </Grid>
      <Grid item xs={12}>
        <ReferenceArrayInput
          reference={ReactAdminResource.AddressArea}
          resource={ReactAdminResource.AddressArea}
          source="relevantDocIds"
          label="areas.input.relevantDocIds"
          filter={addressesFilter}
          validate={validateRequired}
          fullWidth
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </Grid>
    </Grid>
  );
};

export default AdFormFields;
