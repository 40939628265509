import raEnglishMessages from 'ra-language-english';

const englishMessages = {
  ...raEnglishMessages,
  'auth.email': 'Email',
  'auth.errors.unauthorized': 'Unauthorized - 401',
  'ads.resourceLabel': 'Ads',
  'ads.column.id': 'ID',
  'ads.column.desktopBanner': 'Desktop Banner',
  'ads.column.mobileBanner': 'Mobile Banner',
  'ads.input.desktopBannerPath': 'Desktop Banner Path',
  'ads.input.mobileBannerPath': 'Mobile Banner Path',
  'ads.input.externalLink': 'Link',
  'ads.input.package': 'Package',
  'ads.input.startDate': 'Start Date',
  'ads.input.endDate': 'End Date',
  'ads.input.status': 'Status',
  'ads.input.relevantDocs': 'Relevant doc-ids',
  'ads.input.budget': 'Budget',
  'ads.packageName.platina': 'Platina',
  'ads.packageName.control': 'Control',
  'ads.packageName.gold': 'Gold 2020',
  'ads.packageName.listing': 'Listing page',
  'ads.packageName.stickySearchResult': 'Sticky search result page',
  'ads.packageName.controlListing': 'Control listing page',
  'ads.packageName.controlStickySearchResult': 'Control sticky search result page',
  'ads.campaignStatus.active': 'Active',
  'ads.campaignStatus.notActive': 'Inactive',
  'areas.resourceLabel': 'Madad Areas',
  'areas.column.id': 'ID',
  'areas.column.name': 'Area',
  'areas.column.relevantDocIds': 'Cities',
  'areas.input.name': 'Area name',
  'areas.input.relevantDocIds': 'Cities in area',
  'areas.created': 'Area was added',
  'homePageBanner.resourceLabel': 'HP banner',
  'homePageBanner.resourceLabel.new': 'HP banner category',
  'homePageBanner.slide.modal.edit.title': 'slide edit',
  'homePageBanner.slide.modal.edit': 'edit',
  'homePageBanner.slide.modal.create': 'create',
  'homePageBanner.slide.id': 'ID',
  'homePageBanner.slide.type': 'slide type',
  'homePageBanner.slide.title': 'title',
  'homePageBanner.slide.subtitle': 'subtitle',
  'homePageBanner.slide.marketingText': 'marketing text',
  'homePageBanner.slide.navigation': 'navigation',
  'homePageBanner.slide.navigation.text': 'navigation text',
  'homePageBanner.slide.navigation.link': 'navigation link',
  'homePageBanner.slide.aside.link': 'aside link',
  'homePageBanner.slide.aside.image': 'aside image',
  'homePageBanner.slide.aside.imageCssProps.maxWidth': 'max width',
  'homePageBanner.slide.aside.imageCssProps.transformX': 'x translate',
  'homePageBanner.slide.aside.imageCssProps.transformY': 'y translate',
  'homePageBanner.slide.background.image': 'background image',

  'homePageBanner.slide.created': 'slide was added',
  'homePageBanner.slide.deleted': 'slide was deleted',
  'homePageBanner.slide.updated': 'slide was updated',
  'validation.dateRange.startDate': 'Start date is greater than end date',
  'validation.dateRange.endDate': 'End date is less than start date',
  'validation.integerNumber': 'Must be an integer number',
  'validation.pastDate': 'Must be today or earlier',
  'validation.dateFormat': 'Must match DD/MM/YYYY',
  'validation.date': 'Must be valid date (DD/MM/YYYY)',
  'winners.resourceLabel': 'Madad Winners',
  'winners.role.agent': 'Agent',
  'winners.role.office': 'Office',
  'winners.roles': '{role, select, office {Office} agent {Agent}}',
  'winners.column.id': 'ID',
  'winners.column.role': 'Role',
  'winners.column.name': 'Name',
  'winners.column.winningDocId': 'Winning docId',
  'winners.column.winningDocId.soldCount': 'Sold: ',
  'winners.column.winningDocId.exclusiveListingCount': 'Exclusive listings: ',
  'winners.category.top10Gold': 'Top 10 Gold',
  'winners.category.top10': 'Top 10',
  'winners.category.didNotWin': 'Did Not Win',
  'winners.category.top10GoldNotRegistered': 'Top 10 Gold Not Registered',
  'winners.category.top10NotRegistered': 'Top 10 Not Registered',
  'winners.category.neighbourhoodWinner': 'Neighbourhood Winner',
  'winners.input.id': 'Id',
  'winners.input.name': 'Name',
  'winners.input.role': 'Role',
  'winners.input.winningDocId': 'Winning DocIds',
  'winners.input.soldCount': 'Sold count',
  'winners.input.exclusiveListingCount': 'Exclusive listing count',
  'winners.input.category': 'Category',
  'winners.input.docIds': 'Winning DocId',
  'winners.input.dealsHint': 'שים לב: במידה ומוזן ערך בשדה זה, הוא ידרוס  את המספר שקיים כרגע במערכת.',
  'winners.created': 'Winner was added',
  'projectLogoOnMap.resourceLabel': 'Project logo on map',
  'projectLogoOnMap.input.id': 'Project ID',
  'projectLogoOnMap.input.image': 'Project Logo',
  'projectLogoOnMap.column.id': 'Project ID',
  'projectLogoOnMap.column.image': 'Project Logo',
  'projectLogoOnMap.column.marker': 'Project Logo Marker',
  'projectLogoOnMap.logo.created': 'Project Logo was added',
  'projectLogoOnMap.logo.edit': 'Project Logo was edited',
  'projectLogoOnMap.logo.deleted': 'Project Logo was deleted',
  'deals.resourceLabel': 'Manual deals',
  'deals.input.uploadDate': 'Manual upload date',
  'deals.input.gushHelka': 'Gush/Helka',
  'deals.input.settlement': 'City',
  'deals.input.street': 'Street',
  'deals.input.houseNumber': 'House/building number',
  'deals.input.entrance': 'Entrance',
  'deals.input.apartmentNumber': 'Apartment number',
  'deals.input.price': 'Price',
  'deals.input.dealDate': 'Deal date',
  'deals.input.rooms': 'Rooms',
  'deals.input.unitFunctioning': 'Unit Functioning',
  'deals.input.propertyFloorNumber': 'Property floor number',
  'deals.input.totalFloors': 'Total floors',
  'deals.input.constructionYear': 'Construction year',
  'deals.input.partSold': 'Part sold',
  'deals.input.status': 'Status',
  'deals.input.user': 'User',
  'deals.input.lastUpdated': 'Last updated',
  'deals.input.soldId': 'sold_id',
  'deals.input.grossArea': 'Gross area',
  'deals.input.netArea': 'Net area',
  'deals.created': 'Deal was added',
  'deals.create.alreadyExists.title': 'Deal already exists',
  'deals.create.alreadyExists.content': 'Same deal was already added. Please check. \nYou will be redirected to deals table.',
  'deals.create.alreadyExists.ok': 'OK',
  'deals.edit': 'Deal was edited',
  'deals.edit.error': 'Something went wrong. Deal wasn\'t edited',
  'deals.validate.gushHelka.error': 'Must match 000000-0000-000-00 format',
  'deals.validate.entrance.error': 'Must contain Hebrew chars and/or numbers, up to 3 chars long',
  'deals.validate.rooms.error': 'Must be rooms or half rooms',
  'deals.validate.price.error': 'Must be an integer number',
  'deals.validate.apartmentNumber.error': 'Must be an integer number between 1 and 999',
  'deals.validate.houseNumber.error': 'Must be an integer number between 1 and 999',
  'deals.validate.floor.error': 'Must be an integer number between 1 and 999',
  'deals.validate.year.error': 'Must be between 1900-2050',
  'deals.validate.address.error': 'Must contain Hebrew chars, numbers, \', ", - only',
  'deals.validate.area.error': 'Must be an integer number between 1 and 999',
  'ra.navigation.skip_nav': 'skip',
};

export default englishMessages;
