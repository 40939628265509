import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  useNotify,
  useRedirect,
  useRefresh,
  useMutation,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { ReactAdminResource } from 'services/api';
import DealFormFields from './DealFormFields';
import { DEAL_EXISTS_ERROR } from 'components/deals/helpers';
import DealAlreadyExistsDialog from 'components/deals/DealAlreadyExistsDialog';

const DealCreate: React.FC = (props: unknown) => {
  const [ isOpenDialog, setIsOpenDialog ] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [ mutate ] = useMutation();

  const onClose = () => {
    redirect(`/${ReactAdminResource.Deals}`);
    refresh();
  };

  const onSuccess = (response: any) => {
    if (response.data.__typename === DEAL_EXISTS_ERROR) {
      setIsOpenDialog(true);
      return;
    }

    setIsOpenDialog(false);
    notify('deals.created')
    onClose();
  };



  const onCreate =
    async (values?: any) => {
      try {
        await mutate({
          type: 'create',
          resource: ReactAdminResource.Deals,
          payload: { data: values},
        }, {
          returnPromise: true,
          onSuccess,
        });
      } catch (error) {
        notify(error.message);
        onClose();
      }
    };

  const save = (values: any) => onCreate(values);

  return (
    <Grid item container xs={12} md={8}>
      <Create {...props}>
        <SimpleForm autoComplete="off" save={save}>
          <DealFormFields />
        </SimpleForm>
      </Create>
      {isOpenDialog ? (
        <DealAlreadyExistsDialog onClose={onClose} />
      ) : null}
    </Grid>
  );
};

export default DealCreate;
