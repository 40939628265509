import React from 'react';
import {
  TextInput,
  DateInput,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import {
  CampaignStatus,
  CompletionType,
  PackageType,
  ReactAdminResource,
} from 'services/api/types';
import {
  createRequiredValidator,
  createStartDateValidator,
  createEndDateValidator
} from 'utils/validators';
import { useForm } from 'react-final-form';

const validateRequired = [ createRequiredValidator() ];

const validateStartDate = [ /* createRequiredValidator(), */ createStartDateValidator('endDate') ];
const validateEndDate = [ /* createRequiredValidator(), */ createEndDateValidator('startDate') ];

const addressesFilter = {
  completionTypes: [
    CompletionType.City,
    CompletionType.Neighbourhood,
  ]
};

export const packageChoices = [
  { id: PackageType.Platina, name: 'ads.packageName.platina' },
  { id: PackageType.Control, name: 'ads.packageName.control' },
  { id: PackageType.Gold, name: 'ads.packageName.gold' },
  { id: PackageType.Listing, name: 'ads.packageName.listing' },
  { id: PackageType.StickySearchResult, name: 'ads.packageName.stickySearchResult' },
  { id: PackageType.ControlListing, name: 'ads.packageName.controlListing' },
  { id: PackageType.ControlStickySearchResult, name: 'ads.packageName.controlStickySearchResult' },
];

export const statusChoices = [
  { id: CampaignStatus.Active, name: 'ads.campaignStatus.active' },
  { id: CampaignStatus.NotActive, name: 'ads.campaignStatus.notActive' }
];

const AdFormFields: React.FC = () => {
  const form = useForm();

  const displayReferenceArrayInput = (selectedPackage: string) => 
    selectedPackage !== PackageType.ControlListing &&
    selectedPackage !== PackageType.ControlStickySearchResult;

  const onPackageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!displayReferenceArrayInput(e.target.value))
      form.change('relevantDocIds', undefined);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInput
          source="bannerPath"
          label="ads.input.desktopBannerPath"
          fullWidth
          validate={validateRequired}
          resettable
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          source="mobileBannerPath"
          label="ads.input.mobileBannerPath"
          fullWidth
          validate={validateRequired}
          resettable
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          source="externalLink"
          label="ads.input.externalLink"
          fullWidth
          validate={validateRequired}
          resettable
        />
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          source="package"
          label="ads.input.package"
          choices={packageChoices}
          validate={validateRequired}
          fullWidth
          onChange={onPackageChange}
        />
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <NumberInput
            source="budget"
            label="ads.input.budget"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={3}>
          <DateInput
            source="startDate"
            label="ads.input.startDate"
            validate={validateStartDate}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <DateInput
            source="endDate"
            label="ads.input.endDate"
            validate={validateEndDate}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          source="status"
          label="ads.input.status"
          choices={statusChoices}
          validate={validateRequired}
        />
      </Grid>
      <FormDataConsumer>
        {({ formData }) => displayReferenceArrayInput(formData.package) &&
          <Grid item xs={12}>
            <ReferenceArrayInput
              reference={ReactAdminResource.Address}
              resource={ReactAdminResource.Address}
              source="relevantDocIds"
              label="ads.input.relevantDocs"
              filter={addressesFilter}
              validate={validateRequired}
              fullWidth
            >
              <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
          </Grid>
        }
      </FormDataConsumer>
    </Grid>
  );
};

export default AdFormFields;
