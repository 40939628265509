import { DocumentNode } from 'graphql';
import getAllCampaignsQuery from './getAllCampaignsQuery';
import getAddressesQuery from './getAddressesQuery';
import objectStorageUploadUrlQuery from './objectStorageUploadUrlQuery';
import getAllAreasQuery from './getAllAreasQuery';
import getAllWinnersQuery from './getAllWinnersQuery';
import getDealsQuery from './getDealsQuery';
import getDealQuery from './getDealQuery';

export enum LoadType {
  GetAllCampaigns = 'GetAllCampaigns',
  GetAddresses = 'GetAddresses',
  ObjectStorageUploadUrl = 'ObjectStorageUploadUrl',
  GetAllAreas = 'GetAllAreas',
  GetAllWinners = 'GetAllWinners',
  GetProjects = 'GetProjects',
  Deals = 'Deals',
  Deal = 'Deal',
}

export const QUERIES_BY_LOAD_TYPE: Record<LoadType, DocumentNode> = {
  [LoadType.GetAllCampaigns]: getAllCampaignsQuery,
  [LoadType.GetAddresses]: getAddressesQuery,
  [LoadType.ObjectStorageUploadUrl]: objectStorageUploadUrlQuery,
  [LoadType.GetAllAreas]: getAllAreasQuery,
  [LoadType.GetAllWinners]: getAllWinnersQuery,
  [LoadType.GetProjects]: getAddressesQuery,
  [LoadType.Deals]: getDealsQuery,
  [LoadType.Deal]: getDealQuery,
};
