import gql from 'graphql-tag';


export const deal = gql`
    fragment deal on ManualDeal {
        id
        soldId
        lastUpdated
        uploadDate
        gushHelka
        settlement
        street
        houseNumber
        entrance
        apartmentNumber
        price
        dealDate
        grossArea
        netArea
        rooms
        unitFunctioning
        propertyFloorNumber
        totalFloors
        constructionYear
        partSold
        status
        userMail
    }
`;
