import React from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';
import AdFormFields from './AdFormFields';

const AdCreate: React.FC = (props: unknown) => {
  return (
    <Create
      {...props}
    >
      <SimpleForm autoComplete="off">
        <AdFormFields />
      </SimpleForm>
    </Create>
  );
};

export default AdCreate;
