import { AuthProvider } from 'ra-core';
import { GraphQLError } from 'graphql';
import { LOGIN } from 'services/api/build-query';
import { MutationType } from 'services/api/mutations';
import { ResponseByMutationType, ReactAdminResource, UserRole } from 'services/api/types';
import tokenStore from './token-store';

const authProvider: AuthProvider = {
  login: ({ email, password, dataProvider }) => {
    return dataProvider(LOGIN, ReactAdminResource.Auth, { email, password })
      .then((res: ResponseByMutationType[MutationType.Login]['passwordLoginV3']) => {
        if (res.user?.roles?.includes(UserRole.OrangeCmsUser)) {
          tokenStore.set(res.token);
        }
        else {
          return Promise.reject('auth.errors.unauthorized');
        }
      });
  },
  logout: () => {
    tokenStore.remove();
    return Promise.resolve();
  },
  checkError: (error: { graphQLErrors: GraphQLError[] }) => {
    if (error.graphQLErrors?.some(({ message }) => message === 'authorization-error' || message === 'token-invalid')) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return tokenStore.get()
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => Promise.reject('Unknown method'),
};

export default authProvider;
