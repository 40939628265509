import React from 'react';
import {
  Create,
  SimpleForm,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import WinnerFormFields from './WinnerFormFields';
import { ReactAdminResource } from 'services/api';

const WinnerCreate: React.FC = (props: unknown) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('winners.created')
    redirect(`/${ReactAdminResource.Winners}`);
    refresh();
  };

  return (
    <Create
      {...props}
      onSuccess={onSuccess}
    >
      <SimpleForm autoComplete="off">
        <WinnerFormFields />
      </SimpleForm>
    </Create>
  );
};

export default WinnerCreate;
