import gql from 'graphql-tag';

export default gql`
  mutation saveWinner (
    $winner: inputWinner
    $userToken: String!
  ) {
    saveWinner (
      winner: $winner
      userToken: $userToken
    ) {
      agentId
      officeId
    }
  }
`;
