import gql from 'graphql-tag';
import { deal as dealFragment } from './deal';


export const dealResponse = gql`
    fragment dealResponse on ManualDealResponse {
        ... on NoPermissionsError {
            errorMessage
        }
        ... on GeneralManualDealsError {
            errorMessage
        }
        ... on InvalidInputError {
            errorMessage
            invalidField
        }
        ... on ManualDealExistsError {
            errorMessage
        }
        ... on SuccessfulManualDealResponse {
            deal {
                ...deal
            }
        }
    }
    ${dealFragment}
`;
