import config from 'config';

export const insertPerimeterX = () => {
  const { perimeterXAppId } = config;
  if (perimeterXAppId) {
    const script = document.createElement("script");
    script.async = true;
    script.src = '/' + perimeterXAppId.substr(2) + '/init.js';
    document.head.append(script)
  }
};
