import gql from 'graphql-tag';

export default gql`
  mutation deleteCampaign(
    $id: String
    $userToken: String!
  ) {
    deleteCampaign(
      id: $id
      userToken: $userToken
    ) {
      id
    }
  }
`;
