import gql from 'graphql-tag';

export default gql`
  query getAllCampaigns(
    $userToken: String!
  ) {
    getAllCampaigns(
      userToken: $userToken
    ) {
      id
      bannerPath
      mobileBannerPath
      budget
      externalLink
      startDate
      endDate
      status
      package
      relevantDocIds {
        docId
        type
        name
      }
    }
  }
`