import React from 'react';
import {
  Datagrid,
  List,
  SelectField,
  TextField,
  ListProps,
  FunctionField,
  Filter,
  TextInput,
  EditButton,
  BulkExportButton,
} from 'react-admin';
import { keyBy } from 'lodash';
import { UiWinner } from 'services/api/types';
import { useTranslate } from 'ra-core';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { categoryChoices, roleChoices } from './WinnerFormFields';


const categoryChoicesById = keyBy(categoryChoices, 'id');

const useStyles = makeStyles(theme => ({
  relevantDoc: {
    margin: theme.spacing(0.5),
  },
}));

const PostFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);


const PostBulkActionButtons = () => (
  <>
    <BulkExportButton />
  </>
);


const WinnersList: React.FC<ListProps> = props => {
  const translate = useTranslate();
  const classes = useStyles();
  const soldLabel = translate('winners.column.winningDocId.soldCount');
  const exclusiveLabel = translate('winners.column.winningDocId.exclusiveListingCount');

  return (
    <List filters={<PostFilter />} {...props} bulkActionButtons={<PostBulkActionButtons />} perPage={25}>
      <Datagrid optimized>
        <TextField
          source="_id"
          label="winners.column.id"
        />
        <SelectField
          source="role"
          choices={roleChoices}
          label="winners.column.role"
        />
        <TextField
          source="name"
          label="winners.column.name"
        />
        <FunctionField<UiWinner>
          label="winners.column.winningDocId"
          sortable={false}
          render={record => {
            const winningDocId = record?.winningDocId || [];
            return (
              <div>
                {winningDocId.map(({ category, name, soldCount, exclusiveListingCount, docId }, idx) => (
                  <div key={idx}>
                    <span className={classes.relevantDoc}>{translate(categoryChoicesById[category].name)}</span>
                    <span className={classes.relevantDoc}><Chip label={name || docId} /></span>
                    <span className={classes.relevantDoc}>{soldLabel}{soldCount}</span>
                    <span className={classes.relevantDoc}>{exclusiveLabel}{exclusiveListingCount}</span>
                  </div>
                ))}
              </div>
            );
          }}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default WinnersList;
