import gql from 'graphql-tag';

export default gql`
  query getAllWinners (
    $userToken: String!
  ) {
    getAllWinners (
      userToken: $userToken
    ) {
      role
      winningDocId {
        category
        docId
        name
        exclusiveListingCount
        soldCount
      }
      agentId
      agentName
      officeId
      officeName
    }
  }
`;
