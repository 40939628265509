import { createMuiTheme, ThemeOptions as MuiThemeOptions } from '@material-ui/core/styles';
import primary from '@material-ui/core/colors/lightBlue';
import secondary from '@material-ui/core/colors/deepOrange';

interface ThemeOptions extends MuiThemeOptions {
  sidebar: {
    width: number;
  }
}

const options: ThemeOptions = {
  palette: {
    type: 'light',
    primary,
    secondary,
  },
  sidebar: {
    width: 210,
  },
};

export const theme = createMuiTheme(options);
