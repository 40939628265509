import React from 'react';
import {
  DateInput,
  NumberInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { createRequiredValidator } from 'utils/validators';
import {
  houseNumberValidators,
  validateGushHelka,
  entranceValidators,
  apartmentNumberValidators,
  priceValidators,
  roomsValidators,
  floorValidators,
  yearValidators,
  dealPortionValidators,
  addressValidators,
  areaValidators,
  genUnitFunctioningChoices,
  dealDateValidators,
  numberParse,
  numberFormat,
  formatFloor,
  parseFloor,
  dealDateFormat,
  dealDateParse,
} from './helpers';

const validateRequired = [ createRequiredValidator() ];


const DealFormFields: React.FC<{ isEdit?: boolean; }> = ({ isEdit }) => {
  const unitFunctioningChoices = genUnitFunctioningChoices();

  return (
    <Grid item container>
      {isEdit ? (
        <>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={6}>
              <TextInput
                source="userMail"
                label="deals.input.user"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                source="soldId"
                label="deals.input.soldId"
                disabled
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={6}>
              <DateInput
                source="lastUpdated"
                label="deals.input.lastUpdated"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateInput
                source="uploadDate"
                label="deals.input.uploadDate"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </>
      ) : null}

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <TextInput
            source="gushHelka"
            label="deals.input.gushHelka"
            fullWidth
            validate={validateGushHelka}
            resettable
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} md={4}>
          <TextInput
            source="settlement"
            label="deals.input.settlement"
            validate={addressValidators}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            source="street"
            label="deals.input.street"
            validate={addressValidators}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            source="houseNumber"
            label="deals.input.houseNumber"
            validate={houseNumberValidators}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} md={6}>
          <TextInput
            source="entrance"
            label="deals.input.entrance"
            fullWidth
            validate={entranceValidators}
            resettable
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source="apartmentNumber"
            label="deals.input.apartmentNumber"
            fullWidth
            validate={apartmentNumberValidators}
            resettable
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} md={6}>
          <TextInput
            source="dealDate"
            label="deals.input.dealDate"
            validate={dealDateValidators}
            parse={dealDateParse}
            format={dealDateFormat}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source="price"
            parse={numberParse}
            format={numberFormat}
            label="deals.input.price"
            validate={priceValidators}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} md={6}>
          <TextInput
            source="grossArea"
            label="deals.input.grossArea"
            validate={areaValidators}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source="netArea"
            label="deals.input.netArea"
            validate={areaValidators}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} md={4}>
          <TextInput
            source="rooms"
            label="deals.input.rooms"
            validate={roomsValidators}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            source="propertyFloorNumber"
            label="deals.input.propertyFloorNumber"
            format={formatFloor}
            parse={parseFloor}
            validate={floorValidators}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            source="totalFloors"
            label="deals.input.totalFloors"
            format={formatFloor}
            parse={parseFloor}
            validate={floorValidators}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <SelectInput
            source="unitFunctioning"
            label="deals.input.unitFunctioning"
            choices={unitFunctioningChoices}
            validate={validateRequired}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} md={6}>
          <TextInput
            source="constructionYear"
            label="deals.input.constructionYear"
            validate={yearValidators}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberInput
            step={0.25}
            source="partSold"
            label="deals.input.partSold"
            validate={dealPortionValidators}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DealFormFields;
