import React from 'react';
import {
  ListProps,
  List,
  Datagrid,
  TextField,
  FunctionField,
  ImageField,
  BulkExportButton,
  BulkDeleteWithConfirmButton,
  EditButton,
  DeleteWithConfirmButton,
  Filter,
  TextInput,
} from 'react-admin';
import { IProjectLogo, ReactAdminResource } from 'services/api/types';
import config from 'config';

const genMarkerUrl = (val: string | undefined | null, type: 'active' | 'default'): string => {
  if (!val) return '';
  const prefix = `${config.cdnUrl}/t:nonce:v=2;bubble:type=${type};convert:type=webp`;
  return `${prefix}/${encodeURI(val)}`;
}

const PostFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = (props: any) => {
  return (
    <>
      <BulkExportButton {...props} />
      <BulkDeleteWithConfirmButton {...props} />
    </>
  );
};


const ProjectLogoList: React.FC<ListProps> = (props) => {
  return (
    <List
      filters={<PostFilter />}
      {...props}
      bulkActionButtons={<PostBulkActionButtons />}
      perPage={25}
    >
      <Datagrid
        optimized
        resource={ReactAdminResource.ProjectLogoOnMap}
      >
        <TextField source="id" label="projectLogoOnMap.column.id" />
        <ImageField
          source="logo.url"
          label="projectLogoOnMap.column.image"
          sortable={false}
        />
        <FunctionField<IProjectLogo>
          label="projectLogoOnMap.column.marker"
          sortable={false}
          render={record => (
            <>
              <img width="44" src={genMarkerUrl(record?.logo?.title, 'active')} alt={record?.id} />
              <img width="44" src={genMarkerUrl(record?.logo?.title, 'default')} alt={record?.id} />
            </>
          )}
        />
        <EditButton />
        <DeleteWithConfirmButton undoable={false} />
      </Datagrid>
    </List>
  )
}

export default ProjectLogoList;
