import gql from 'graphql-tag';

export default gql`
  mutation editCampaign(
    $campaign: inputCampaign
    $userToken: String!
  ){
    editCampaign(
      campaign: $campaign
      userToken: $userToken
    ) {
      id
    }
  }
`;