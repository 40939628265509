import React from 'react';
import { SelectField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { CampaignStatus } from 'services/api/types';


const statusFieldStyles = {
  active: {
    color: 'green'
  },
  inactive: {
    color: 'grey'
  },
};

export const StatusField = withStyles(statusFieldStyles)((props: any) => {
  const { classes, ...restProps } = props;

  return (
    <SelectField
      className={classnames({
        [classes.active]: props.record?.[props.source] === CampaignStatus.Active,
        [classes.inactive]: props.record?.[props.source] === CampaignStatus.NotActive,
      })}
      {...restProps}
    />
  )
});

StatusField.defaultProps = SelectField.defaultProps;
