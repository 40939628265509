import gql from 'graphql-tag';

export default gql`
  mutation passwordLoginV3 (
    $email: String
    $password: String
    $userToken: String
  ) {
    passwordLoginV3 (
      email: $email
      password: $password
      visitorToken: $userToken
    ) {
      token
      errors
      user {
        firstName
        lastName
        roles
      }
    }
  }
`;
