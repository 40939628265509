import gql from 'graphql-tag';

export default gql`
  query getAddresses (
    $text: String!
    $completionTypes: [String]
  ) {
    addresses (
      text: $text,
      completionTypes: $completionTypes
    ) {
      id
      docId
      type
      name
    }
  }
`;
