import React from 'react';
import { Edit } from 'react-admin';

import { ProjectLogoForm } from 'components/project-logo/ProjectLogoForm';


const ProjectLogoEdit: React.FC = (props) => {
  return (
    <Edit
      {...props}
      undoable={false}
    >
      <ProjectLogoForm isEdit autoComplete="off" />
    </Edit>
  );
};

export default ProjectLogoEdit;
