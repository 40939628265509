const TOKEN_KEY = 'ADS_BACKOFFICE_TOKEN';

export interface UserTokenStore {
  get: () => string | null;
  set: (token: string) => void;
  remove: () => void;
}

class TokenStore implements UserTokenStore {
  public get() {
    return localStorage.getItem(TOKEN_KEY);
  }

  public set(token: string) {
    if (token) {
      localStorage.setItem(TOKEN_KEY, token);
    }
  }

  public remove() {
    localStorage.removeItem(TOKEN_KEY);
  }
}

const tokenStore: UserTokenStore = new TokenStore();

export default tokenStore;