import { Config } from './';

const config: Config = {
  apiEndpoint: 'https://www.madlan.co.il/api2',
  newApiEndpoint: 'https://www.madlan.co.il/api3',
  s3Endpoint: 'https://s3-eu-west-1.amazonaws.com/media.madlan.co.il',
  HPBannerPath: 'home-page-banner',
  HPBannerWithCategoriesPath: 'home-page-banner-with-categories',
  projectLogoPath: 'project-logo',
  cdnUrl: 'https://images2.madlan.co.il',
  perimeterXAppId: 'PXo4wPDYYd',
};

export default config;
