import gql from 'graphql-tag';

export default gql`
  query objectStorageUploadUrl (
    $fileName: String!
    $fileType: String!
    $objectType: ObjectStorageType!
    $userToken: String!
  ) {
    objectStorageUploadUrl (
      fileName: $fileName,
      fileType: $fileType,
      objectType: $objectType,
      userToken: $userToken
    ) {
      error
      message
      name
      url
    }
  }
`;
