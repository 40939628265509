import gql from 'graphql-tag';

export default gql`
  mutation deleteAreas (
    $areas: [inputArea]
    $userToken:String!
  ) {
    deleteAreas (
      areas: $areas
      userToken: $userToken
    ) {
      areaId
    }
  }
`;
