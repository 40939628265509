import gql from 'graphql-tag';
import { deal as dealFragment } from './deal';


export const dealsResponse = gql`
    fragment dealsResponse on ManualDealsResponse {
        ... on NoPermissionsError {
            errorMessage
        }
        ... on GeneralManualDealsError {
            errorMessage
        }
        ... on InvalidInputError {
            errorMessage
            invalidField
        }
        ... on SuccessfulManualDealsResponse {
            deals {
                ...deal
            }
            totalDeals
        }
    }
    ${dealFragment}
`;
