import { required, maxLength } from 'react-admin';
import { isNaN, isNumber, trim, ceil, parseInt } from 'lodash';

export const createRequiredValidator = required;

export const createMaxLength = maxLength;

export const createStartDateValidator = (endDateKey: string, message = 'validation.dateRange.startDate') => {
  return (value: string, values: Record<string, any>) => {
    if (value && values[endDateKey] && new Date(value).getTime() > new Date(values[endDateKey]).getTime()) {
      return message;
    }
    return undefined;
  };
}

export const createEndDateValidator = (startDateKey: string, message = 'validation.dateRange.endDate') => {
  return (value: string, values: Record<string, any>) => {
    if (value && values[startDateKey] && new Date(value).getTime() < new Date(values[startDateKey]).getTime()) {
      return message;
    }
    return undefined;
  };
}

export const createCeilNumberValidator = (message = 'validation.integerNumber') => {
  return (value: string | number) => {
    if (value === undefined) return;
    const numVal = isNumber(value) ? value : parseFloat(trim(value));

    if (isNaN(numVal) || numVal !== ceil(numVal)) return message;
  }
};

export const createValidateDate = (message = 'validation.date') => {
  return (value: string) => {
    const separator = '/';
    const [ day, month, year ] = trim(value).split(separator);
    const date = new Date(`${month}${separator}${day}${separator}${year}`);
    const parsedDay = date.getDate();
    const parsedMonth = date.getMonth() + 1;
    const parsedYear = date.getFullYear();
    if (parseInt(day) === parsedDay && parseInt(month) === parsedMonth && parseInt(year) === parsedYear) {
      return undefined;
    }

    return message;
  }
}

export const createPastDateValidator = (message = 'validation.pastDate') => {
  return (value: string) => {
    const separator = '/';
    const [ day, month, year ] = trim(value).split(separator);
    const date = new Date(`${month}${separator}${day}${separator}${year}`);

    const today = new Date();
    return today < date ? message : undefined;
  };
};
