import React from 'react';
import {
  ListProps,
  useCreateController,
  CreateContextProvider,
  CreateControllerProps,
  useNotify,
} from 'react-admin';
import { SlideType } from 'services/api/types';
import { generateUUID } from 'utils/uuid';
import { SlideForm } from './SlideForm';


function getCreateFormProps({ basePath, record, redirect, resource, save, saving, version }: CreateControllerProps) {
  return { basePath, record, redirect, resource, save, saving, version };
}

interface SlideCreateProps extends ListProps {
  slideType: SlideType;
  onCreate: () => void;
}

export const SlideCreate: React.FC<SlideCreateProps> = ({ slideType, onCreate, ...props }) => {
  const notify = useNotify();
  const onSuccess = () => {
    onCreate();
    notify(`homePageBanner.slide.created`);
  }
  const controllerProps = useCreateController({ ...props, onSuccess, });
  const formProps = getCreateFormProps(controllerProps);
  const id = React.useMemo(generateUUID, [ generateUUID ]);

  return (
    <CreateContextProvider value={controllerProps}>
      <SlideForm {...formProps} slideType={slideType} initialId={id} />
    </CreateContextProvider>
  );
}