import React from 'react';
import {
  CreateControllerProps,
  useCreateController,
  useNotify,
  useRedirect,
  useRefresh
} from 'react-admin';
import { ProjectLogoForm } from './ProjectLogoForm';
import { ReactAdminResource } from 'services/api';


function getCreateFormProps({ basePath, record, redirect, resource, save, saving, version }: CreateControllerProps) {
  return { basePath, record, redirect, resource, save, saving, version };
}

const ProjectLogoCreate: React.FC<any> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`projectLogoOnMap.logo.created`);
    redirect(`/${ReactAdminResource.ProjectLogoOnMap}`);
    refresh();
  }
  const controllerProps = useCreateController({ ...props, onSuccess, });
  const formProps = getCreateFormProps(controllerProps);

  return (
    <ProjectLogoForm autoComplete="off" {...formProps} />
  );
}

export default ProjectLogoCreate;
