import gql from 'graphql-tag';
import { dealsResponse as dealsResponseFragment } from 'services/api/fragments/dealsResponse';


export default gql`
    query getManualDeals (
        $userToken: UserToken!
        $offset: Int
        $limit: Int
        $sort: Sort
    ) {
        getManualDeals(
            userToken: $userToken
            offset: $offset
            limit: $limit
            sort: $sort
        ) {
            ...dealsResponse
        }
    }
    ${dealsResponseFragment}
`;
