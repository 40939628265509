import { maxValue, minValue, regex, required, number } from 'react-admin';
import { memoize, values, isNumber, parseInt, trim } from 'lodash';
import { createCeilNumberValidator, createPastDateValidator, createValidateDate } from 'utils/validators';


export const DEAL_EXISTS_ERROR = 'ManualDealExistsError';
const GUSH_HELKA_SEPARATOR = '-';
const GUSH_HELKA_REGEX = /^\d{6}-\d{4}-\d{3}-\d{2}$/;

export const validateGushHelka = [ required(), regex(GUSH_HELKA_REGEX, 'deals.validate.gushHelka.error') ];

export const formatGushHelka = (value: string) => {
  let result: string = trim(value).replace(/\D/gi, '') || '';

  if (!result) return undefined;

  const arr: string[] = result.split('');

  return [
    ...arr.slice(0, 6),
    (arr.length > 5 ? GUSH_HELKA_SEPARATOR : null),
    ...arr.slice(6, 10),
    (arr.length > 9 ? GUSH_HELKA_SEPARATOR : null),
    ...arr.slice(10, 13),
    (arr.length > 12 ? GUSH_HELKA_SEPARATOR : null),
    ...arr.slice(13, 15)
  ].join('');
};

export const numberParse = (value: string) => trim(value).replace(/\D/gi, '') || '';
export const numberFormat = (value: number | string): string => {
  if (isNumber(value)) return value.toLocaleString();
  if (!value) return '';
  let parsedValue = parseInt(trim(value).replace(/\D/gi, ''))
  return parsedValue?.toLocaleString('en-US');
};

const MIN_HOUSE_NUMBER = 0;
const MAX_HOUSE_NUMBER = 9999;
export const houseNumberValidators = [
  required(),
  number(),
  minValue(MIN_HOUSE_NUMBER),
  maxValue(MAX_HOUSE_NUMBER),
  createCeilNumberValidator('deals.validate.houseNumber.error'),
];

const ENTRANCE_REGEX = /^[\d\u0590-\u05fe]{1,3}$/;
export const entranceValidators = [ regex(ENTRANCE_REGEX, 'deals.validate.entrance.error') ];

const MIN_APARTMENT_NUMBER = 0;
const MAX_APARTMENT_NUMBER = 999;
export const apartmentNumberValidators = [
  number(),
  minValue(MIN_APARTMENT_NUMBER),
  maxValue(MAX_APARTMENT_NUMBER),
  createCeilNumberValidator('deals.validate.apartmentNumber.error'),
];

const MIN_PRICE = 0;
const MAX_PRICE = 99999999;
export const priceValidators = [
  required(),
  number(),
  minValue(MIN_PRICE),
  maxValue(MAX_PRICE),
  createCeilNumberValidator('deals.validate.price.error'),
];

const MIN_ROOMS = 1;
const MAX_ROOMS = 999;
const ROOMS_REGEX = /^(?:\d{1,3}\.5|\d{1,3})$/;
export const roomsValidators = [
  required(),
  number(),
  minValue(MIN_ROOMS),
  maxValue(MAX_ROOMS),
  regex(ROOMS_REGEX, 'deals.validate.rooms.error'),
];

const MIN_FLOORS = -1;
const MAX_FLOORS = 999;
export const floorValidators = [
  required(),
  number(),
  minValue(MIN_FLOORS),
  maxValue(MAX_FLOORS),
  createCeilNumberValidator(),
];

const createYearValidator = (message = 'deals.validate.year.error') => {
  const MIN_YEAR = 1900;
  const MAX_YEAR = 2050;
  return (value: string) => {
    const year = parseInt(value, 10);
    return !!year && (year < MIN_YEAR || year > MAX_YEAR) ? message : undefined;
  };
};

export const yearValidators = [ required(), createYearValidator() ];

const MIN_DEAL_PORTION = 0;
const MAX_DEAL_PORTION = 1;
export const dealPortionValidators = [
  required(),
  number(),
  maxValue(MAX_DEAL_PORTION),
  minValue(MIN_DEAL_PORTION),
];

const ADDRESS_REGEX = /^[\d\s\u0590-\u05fe"'-]+$/;
export const addressValidators = [ required(), regex(ADDRESS_REGEX, 'deals.validate.address.error') ];

const MIN_AREA = 1;
export const areaValidators = [
  required(),
  number(),
  minValue(MIN_AREA),
  createCeilNumberValidator('deals.validate.area.error'),
];

const DEAL_DATE_REGEX = /^\d{2}\/\d{2}\/\d{4}$/;

export const dealDateValidators = [ required(), regex(DEAL_DATE_REGEX, 'validation.dateFormat'), createValidateDate(), createPastDateValidator() ];

enum UnitFunctioning {
  Apartment = 'דירה בבית קומות',
  Duplex = 'דירת דופלקס',
  Penthouse = 'דירת גג )פנטהאוז(', // todo ???
  Cottage = 'קוטג\' דו-משפחתי',
  Cottage2 = 'קוטג\' חד-משפחתי',
  GardenApartment = 'דירת גן',
  Cottage3 = 'קוטג\' טורי',
  House = 'בית בודד',
  GeneralHousing = 'מגורים',
  PrivateToPrivate = 'מפרטי לפרטי',
  CompanyToPrivate = 'מחברה לפרטי',
  PrivateToCompany = 'מפרטי לחברה',
  CompanyToCompany = 'מחברה לחברה',
  MixedHousing = 'בבנין מעורב מגורים',
}

const UnitFunctioningToLabelMap: Partial<Record<UnitFunctioning, string>> = {
  [UnitFunctioning.Apartment]: 'דירה',
  [UnitFunctioning.Duplex]: 'דופלקס',
  [UnitFunctioning.Penthouse]: 'פנטהאוז',
};


export const genUnitFunctioningChoices = memoize(() => {
  return values(UnitFunctioning).map(val => ({
    id: val,
    name: UnitFunctioningToLabelMap[val] || val,
  }));
});

const BASEMENT = 'מרתף';
const GROUND_FLOOR = 'קרקע';

export const formatFloor = (val: string) => {
  const str = trim(val);
  if (str === '-') return str;

  const parsedVal = parseFloat(str);

  if (parsedVal === -1) {
    return BASEMENT;
  }
  else if (parsedVal === 0) {
    return GROUND_FLOOR;
  }

  return str;
};

export const parseFloor = (val: string) => trim(val);

export const dealDateParse = (val: string) => dealDateFormat(val);

export const dealDateFormat = (value: string) => {
  let result: string = trim(value).replace(/\D/gi, '') || '';
  if (!result) return '';

  const separator = '/';

  const arr: string[] = result.split('');

  return [
    ...arr.slice(0, 2),
    (arr.length > 2 ? separator : null),
    ...arr.slice(2, 4),
    (arr.length > 4 ? separator : null),
    ...arr.slice(4, 8),
  ].join('');
};
