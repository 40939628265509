import React from 'react';
import { Tabs, Tab,AppBar, makeStyles } from '@material-ui/core'
import { SlidersList } from './SlidersList';
import { ISlideWithCategories, SlideType } from 'services/api/types';


const TAB_LIST = [ SlideType.Desktop, SlideType.Mobile ];

interface SliderTabsProps {
  setEditSlideId: React.Dispatch<React.SetStateAction<string | null>>;
  setSlideType: React.Dispatch<React.SetStateAction<SlideType>>;
  slideType: SlideType;
  slides: ISlideWithCategories[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const SliderTabs: React.FC<SliderTabsProps> = ({ setEditSlideId, setSlideType, slideType, slides }) => {
  const classes = useStyles();

  const handleTabChange = (_: React.ChangeEvent<{}>, value: SlideType) => {
    setSlideType(value);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={slideType} onChange={handleTabChange}>
          {TAB_LIST.map((tab) => <Tab label={tab} value={tab} key={`${tab}-head`} />)}
        </Tabs>
      </AppBar>
      {TAB_LIST.map((tab) => (
        <div
          role="tabpanel"
          key={`${tab}-body`}
          hidden={tab !== slideType}
        >
          {tab === slideType ? <SlidersList onEdit={setEditSlideId} slides={slides} /> : null}
        </div>
      ))}
    </div>
  );
};