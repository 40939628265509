import { Modify } from 'utils/types';
import { MutationType } from './mutations';
import { LoadType } from './queries';

export enum ReactAdminResource {
  Ads = 'ads',
  ObjectStorageURL = 'objectStorageURL',
  HPBanner = 'HPBanner',
  HPBannerWithCategories = 'HPBannerWithCategories',
  Address = 'address',
  Auth = 'auth',
  Areas = 'madad-areas',
  AddressArea = 'addressArea',
  Winners = 'madad-winners',
  AddressWinners = 'addressWinners',
  ProjectLogoOnMap = 'project-logo-on-map',
  Deals = 'manual-deals',
}

export enum GqlResource {
  Ad = 'Ad',
  HPBanner = 'HPBanner',
  ObjectStorageURL = 'ObjectStorageURL',
  Address = 'Address',
  Auth = 'Auth',
  Area = 'Area',
  AddressArea = 'AddressArea',
  AddressWinners = 'AddressWinners',
  Winners = 'Winners',
  Deals = 'Deals',
}

export enum UserRole {
  User = 'user',
  OrangeCmsUser = 'orange_cms_user'
}

export interface UploadedFile {
  name: string;
  s3url: string;
  cdnUrl: string;
}

export type UploadFileType = 'aside' | 'background';

export interface UploadFile {
  file?: SliderFile;
  fileName: UploadFileType;
}

export enum SlideType {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export type SliderStatus = 'publish' | 'draft';

export interface SliderFile {
  url: string | null;
  rawFile?: File;
  title: string | null;
}

export interface ISlide {
  id: string;
  title: string;
  subtitle: string;
  slideType: SlideType;
  status: SliderStatus;
  navigation: {
    text: string;
    link: string;
  };
  aside: {
    link: string;
    file: SliderFile;
    imageCssProps?: {
      maxWidth: number;
      transformX: number;
      transformY: number;
    };
  };
  backgroundFile: SliderFile;
}

export interface ISlideWithCategories {
  id: string;
  marketingText: string;
  slideType: SlideType;
  status: SliderStatus;
  navigation: {
    text: string;
    link: string;
  };
  aside: {
    link: string;
    file: SliderFile;
    imageCssProps?: {
      maxWidth: number;
      transformX: number;
      transformY: number;
    };
  };
}

export type IBanner = { id: 'hp-banner' } & Record<SlideType, ISlide[]>;
export type IBannerWithCategories = { id: 'hp-banner-with-categories' } & Record<SlideType, ISlideWithCategories[]>;

export interface IProjectLogo {
  id: string;
  logo: SliderFile;
  file: string;
}

export interface IProjectLogoList {
  id: 'project-logo';
  items: IProjectLogo[];
}

export interface LogoFile {
  file: SliderFile;
  fileName: 'logo';
}

interface User {
  firstName: string;
  lastName: string;
  roles: UserRole[];
}

export enum CompletionType {
  City = 'city',
  Neighbourhood = 'neighbourhood',
  Project = 'project',
}

export enum PackageType {
  Platina = 'Platina2020',
  Control = 'Control',
  Gold = 'Gold2020',
  Listing = 'Listing',
  StickySearchResult = 'Sticky_Search_Result',
  ControlListing = 'Control_Listing',
  ControlStickySearchResult = 'Control_Sticky_Search_Result',
}

export enum CampaignStatus {
  Active = 'Active',
  NotActive = 'NotActive'
}

export interface Campaign {
  id: string;
  bannerPath: string;
  mobileBannerPath: string;
  budget: number;
  externalLink: string;
  package: PackageType;
  relevantDocIds: RelevantDoc[];
  status: CampaignStatus;
  startDate: string;
  endDate: string;
}

export type UiCampaign = Modify<Campaign, {
  relevantDocIds: string[];
  relevantDocs: RelevantDoc[];
}>;

export interface BaseArea {
  areaName: string;
  relevantDocIds: RelevantDoc[];
}

export interface Area extends BaseArea {
  id: number;
  areaId: number;
}

export type UiArea = Modify<Area, {
  relevantDocIds: string[];
  relevantDocs: RelevantDoc[];
}>;

export enum WinningCategories {
  Top10Gold = 'top_10_gold',
  Top10 = 'top_10',
  DidNotWin = 'did_not_win',
  Top10GoldNotRegistered = 'top_10_gold_not_registered',
  Top10NotRegistered = 'top_10_not_registered',
  NeighbourhoodWinner = 'neighbourhood_winner',
}

export enum WinnerRole {
  Agent = 'agent',
  Office = 'office',
}

export interface WinningDocId {
  category: WinningCategories;
  docId: string;
  name?: string;
  exclusiveListingCount: number;
  soldCount: number;
}

interface WinnerBase {
  role: WinnerRole;
  winningDocId: WinningDocId[];
}

export interface Agency extends WinnerBase {
  role: WinnerRole.Office;
  officeId: string;
  officeName: string;
}

export interface Agent extends WinnerBase {
  role: WinnerRole.Agent;
  agentId: string;
  agentName: string;
}

export type Winner = Agent | Agency;

export interface UiWinner {
  _id: string;
  id: string;
  name: string;
  role: WinnerRole;
  winningDocId: WinningDocId[];
}

interface IDealBase {
  gushHelka: string;
  settlement: string;
  street: string;
  houseNumber: number;
  entrance?: string;
  apartmentNumber?: number | null;
  price: number;
  dealDate: string;
  grossArea: number;
  netArea: number;
  rooms: number;
  unitFunctioning: string;
  propertyFloorNumber: number;
  totalFloors: number;
  constructionYear: number;
  partSold: number;
}

export interface IDeal extends IDealBase {
  id: string;
  lastUpdated: string;
  uploadDate: string;
  status: string;
  soldId: string;
  userMail: string;
}

export interface INewDealInput extends IDealBase {}
export interface IEditDealInput extends INewDealInput {
  id: string;
}

export interface ResponseByLoadType {
  [LoadType.GetAllCampaigns]: {
    getAllCampaigns: Campaign[];
  },
  [LoadType.GetAddresses]: {
    addresses: AutocompleteEntry[];
  }
  [LoadType.ObjectStorageUploadUrl]: {
    objectStorageUploadUrl: ObjectStorageUploadUrl;
  }
  [LoadType.GetAllAreas]: {
    getAllAreas: Area[];
  }
  [LoadType.GetAllWinners]: {
    getAllWinners: Winner[];
  }
  [LoadType.Deals]: {
    getManualDeals: {
      deals: IDeal[];
      totalDeals: number;
    }
  }
  [LoadType.Deal]: {
    getManualDeal: {
      deal: IDeal;
    }
  }
}

export interface VariablesByLoadType {
  [LoadType.Deals]: {
    limit: number;
    offset: number;
    sort: {
      field: string;
      asc: boolean;
    };
  };
  [LoadType.Deal]: {
    dealId: string;
  };
}

export interface ResponseByMutationType {
  [MutationType.SaveCampaign]: {
    saveCampaign: Array<{ id: string }>;
  };
  [MutationType.EditCampaign]: {
    editCampaign: { id: string };
  };
  [MutationType.Login]: {
    passwordLoginV3: {
      token: string;
      user: User;
    }
  };
  [MutationType.SaveArea]: {
    saveArea: { areaId: number };
  };
  [MutationType.EditArea]: {
    saveArea: { areaId: number };
  };
  [MutationType.SaveWinner]: {
    saveWinner: { agentId: string; officeId: string; }
  };
  [MutationType.NewDeal]: {
    newManualDeal: {
      deal: IDeal;
      '__typename'?: string;
      errorMessage?: string;
    };
  };
  [MutationType.DeleteDeals]: {
    deleteManualDeals: { result: number; };
  };
  [MutationType.EditDeal]: {
    editManualDeal: { deal: IDeal; };
  };
}

export interface VariablesByMutationType {
  [MutationType.EditCampaign]: {
    campaign: Campaign
  };
  [MutationType.EditArea]: {
    area: Area;
  };
  [MutationType.SaveWinner]: {
    winner: Winner;
  };
  [MutationType.NewDeal]: {
    newManualDeal: INewDealInput;
    forceCreate: boolean;
  };
  [MutationType.DeleteDeals]: {
    deleteDeals: string[];
  };
  [MutationType.EditDeal]: {
    editManualDeal: IEditDealInput;
  };
}

export interface BaseAutocompleteDoc {
  id: string;
  docId: string;
  type: CompletionType;
  name: string;
}

export interface AutocompleteCity extends BaseAutocompleteDoc {
  type: CompletionType.City;
}

export interface AutocompleteNeighbourhood extends BaseAutocompleteDoc {
  type: CompletionType.Neighbourhood;
}

export type AutocompleteEntry =
  | AutocompleteCity
  | AutocompleteNeighbourhood;

export type UiAutocompleteEntry = AutocompleteEntry & { _id: string };

export type RelevantDoc = Pick<AutocompleteEntry, 'docId' | 'name' | 'type'>;

export interface ObjectStorageUploadUrl {
  error: boolean;
  message: string;
  name: string;
  url: string;
}
