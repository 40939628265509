import gql from 'graphql-tag';

export default gql`
    mutation deleteManualDeals (
        $userToken: UserToken!
        $deleteDeals: [String]!
    ) {
        deleteManualDeals (
            userToken: $userToken
            deleteManualDeals: $deleteDeals
        ) {
            ... on NoPermissionsError {
                errorMessage
            }
            ... on GeneralManualDealsError {
                errorMessage
            }
            ... on InvalidInputError {
                errorMessage
                invalidField
            }
            ... on DeleteResponse {
                result
            }
        }
    }
`;
