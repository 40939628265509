import gql from 'graphql-tag';
import { dealResponse as dealResponseFragment} from 'services/api/fragments/dealResponse';

export default gql`
    mutation newManualDeal (
        $userToken: UserToken!
        $newManualDeal: ManualDealInput!
        $forceCreate: Boolean
    ) {
        newManualDeal (
            userToken: $userToken
            newManualDeal: $newManualDeal
            forceCreate: $forceCreate
        ) {
            ...dealResponse
        }
    }
    ${dealResponseFragment}
`;
