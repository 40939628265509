import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import {
  List,
  Datagrid,
  EditButton,
  FunctionField,
  ImageField,
  SelectField,
  NumberField,
  TextField,
  UrlField,
  ListProps,
  BulkExportButton,
  BulkDeleteWithConfirmButton,
  DeleteWithConfirmButton,
} from 'react-admin';
import DateFieldCustom from 'components/date-field';
import { packageChoices, statusChoices } from './AdFormFields';
import { UiCampaign } from 'services/api/types';
import { StatusField } from './fields/StatusField';

const useStyles = makeStyles(theme => ({
  relevantDoc: {
    margin: theme.spacing(0.5),
  },
  urlField: {
    display: 'block',
    maxWidth: '200px',
    overflow: 'hidden',
    wordWrap: 'break-word',
  },
  relevantDocsField: {
    display: 'block',
    minWidth: '200px',
  },
  packageActive: {
    color: 'blue',
  },
  packageInactive: {
    color: 'red',
  }
}));

const PostBulkActionButtons = (props: any) => {
  return (
    <>
      <BulkExportButton {...props} />
      <BulkDeleteWithConfirmButton {...props} />
    </>
  );
};

const AdsList: React.FC<ListProps> = props => {
  const classes = useStyles();

  return (
    <List
      {...props}
      bulkActionButtons={<PostBulkActionButtons />}
      pagination={false}
    >
      <Datagrid
        optimized
      >
        <TextField
          source="id"
          label="ads.column.id"
          sortable={false}
        />
        <ImageField
          source="bannerPath"
          label="ads.column.desktopBanner"
          sortable={false}
        />
        <ImageField
          source="mobileBannerPath"
          label="ads.column.mobileBanner"
          sortable={false}
        />
        <UrlField
          source="externalLink"
          label="ads.input.externalLink"
          sortable={false}
          className={classes.urlField}
        />
        <SelectField
          source="package"
          choices={packageChoices}
          sortable={false}
        />
        <NumberField
          source="budget"
          label="ads.input.budget"
          sortable={false}
        />
        <StatusField
          source="status"
          choices={statusChoices}
          sortable={false}
        />
        <DateFieldCustom
          source="startDate"
          label="ads.input.startDate"
          sortable={false}
        />
        <DateFieldCustom
          source="endDate"
          label="ads.input.endDate"
          sortable={false}
        />
        <FunctionField<UiCampaign>
          label="ads.input.relevantDocs"
          sortable={false}
          className={classes.relevantDocsField}
          render={record => {
            return (
              <div>
                {record?.relevantDocs?.map(doc => (
                  <Chip key={doc.name} className={classes.relevantDoc} label={doc.name} />
                ))}
              </div>
            );
          }}
        />
        <EditButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};

export default AdsList;
