import React, { useState } from 'react';
import {
  ListProps,
  useEditController,
  EditContextProvider,
  EditControllerProps,
  ToolbarProps,
  useRefresh,
} from 'react-admin';
import { SlideForm } from './SlideForm';
import { useMutation, useNotify, CRUD_DELETE } from 'react-admin';
import { Button } from '@material-ui/core';
import { SlideType } from 'services/api/types';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';


interface EditToolBarProps extends ToolbarProps {
  onSave: () => void;
  onDelete: () => void;
}

const EditToolBar: React.FC<EditToolBarProps> = ({ onSave, onDelete, handleSubmit, ...props }) => {
  const [mutate, { loading }] = useMutation();
  const [disableDelete, setDisableDelete] = useState(true);

  const notify = useNotify();
  const refresh = useRefresh();

  const afterDelete = (success: boolean) => {
    onDelete();
    refresh();
    if (success) {
      notify(`homePageBanner.slide.deleted`);
    }
  };

  const toggleDisableDelete = () => setDisableDelete(!disableDelete);

  const handleDelete = () => mutate(
    {
      type: 'delete',
      resource: props.resource,
      payload: { id: props?.record?.id, previousData: props?.record }
    },
    {
      action: CRUD_DELETE,
      mutationMode: 'optimistic',
      onSuccess: () => afterDelete(true),
      onFailure: () => afterDelete(false),
    }
  );

  const handleSave = () => {
    if (handleSubmit) {
      handleSubmit();
    }
    onSave();
  }

  return (
    <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-between' }}>
      <Button
        onClick={handleSave}
        color="primary"
        variant="contained"
        disabled={props.pristine || loading}
      >
        {'save'}
      </Button>
      <div
        style={{
          width: '100%',
          margin: '0 10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          cursor: 'pointer'
        }}
        onClick={toggleDisableDelete}
      >
        {disableDelete ? <LockIcon /> : <LockOpenIcon />}
      </div>
      <Button
        onClick={handleDelete}
        variant="contained"
        disabled={loading || disableDelete}
        color="secondary"
      >
        DELETE
      </Button>
    </div>
  );
};

interface SlideEditProps extends ListProps {
  id: string;
  slideType: SlideType;
  onSave: () => void;
  onDelete: () => void;
}

export const SlideEdit: React.FC<SlideEditProps> = ({ slideType, onSave, onDelete, ...props }) => {
  const controllerProps: EditControllerProps = useEditController(props);
  const { basePath, record, redirect, resource, save, saving, version } = controllerProps;
  const formProps = {
    basePath,
    record,
    redirect,
    resource,
    save,
    saving,
    version,
    mutationMode: 'optimistic',
    toolbar: <EditToolBar onSave={onSave} onDelete={onDelete} />
  };

  return (
    <EditContextProvider value={controllerProps}>
      <SlideForm {...formProps} slideType={slideType} />
    </EditContextProvider>
  );
}
