import gql from 'graphql-tag';

export default gql`
  mutation saveCampaign(
    $campaign: inputCampaign
    $userToken: String!
  ) {
    saveCampaign(
      campaign: $campaign
      userToken: $userToken
    ) {
      id
    }
  }
`;
