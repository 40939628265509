import { orderBy, trim, values, isObject, isArray, toLower, isEmpty } from 'lodash';
export const DEFAULT_PAGE = 1;
export const DEFAULT_PER_PAGE = 25;
const DEFAULT_SORT_FIELD = 'id';
const DEFAULT_SORT_ORDER = 'asc';


const filterDeep = (obj: any, search: string): boolean => {
  if (!isObject(obj)) {
    return toLower(`${obj}`).indexOf(search) > -1;
  }

  const objValues = isArray(obj) ? obj : values(obj);
  for (const val of objValues) {
    if (isObject(val) && isEmpty(val)) continue;
    if (filterDeep(val, search)) {
      return true;
    }
  }

  return false;
};

interface IFilterByParams {
  data: any[];
  total: number;
}

interface IFilterConfig {
  searchBy?: Array<{
    name: string;
    filterFn?: (val: any, search: string)=> boolean;
  }>;
}

export const filterByParams = (data: any[], params: any, config?: IFilterConfig ): IFilterByParams => {
  const {
    pagination: { page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE },
    sort: { field = DEFAULT_SORT_FIELD, order = DEFAULT_SORT_ORDER },
    filter: { q },
  } = params;

  let filteredData = data;
  const search = trim(q);

  if (search) {
    if (config && Array.isArray(config.searchBy) && config.searchBy.length) {
      filteredData = data.filter(item => {
        return config.searchBy?.reduce((res: boolean, { name, filterFn }) => {
          const val = item[name];
          if (filterFn) {
            return Boolean(res || filterFn(val, search))
          }
          return Boolean(res || toLower(val).indexOf(search) > -1);
        }, false);
      });
    }
    else {
      filteredData = data.filter(item => filterDeep(item, toLower(search)));
    }
  }
  const sortedData = orderBy(filteredData, [ field ], [ order.toLocaleLowerCase() ]);
  const sortedPagedData = sortedData.slice((page - 1) * perPage, page * perPage);

  return ({ data: sortedPagedData, total: filteredData.length });
};
