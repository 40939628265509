import devConfig from './dev-config';
import prodConfig from './prod-config';
import stageConfig from './stage-config';

export interface Config {
  apiEndpoint: string;
  newApiEndpoint: string;
  s3Endpoint: string;
  HPBannerPath: string;
  HPBannerWithCategoriesPath: string;
  projectLogoPath: string;
  cdnUrl: string;
  perimeterXAppId: string;
}

function getConfigByUrl(url: string): Config {
  if (url.includes('localhost')) return devConfig;

  if (url.includes('madlan-internal.com')) return stageConfig;

  return prodConfig
}

const config = getConfigByUrl(window.location.href);

export default config;
