import gql from 'graphql-tag';
import { dealResponse as dealResponseFragment } from 'services/api/fragments/dealResponse';


export default gql`
    mutation editManualDeal (
        $userToken: UserToken!
        $editDeal: EditManualDealInput
    ) {
        editManualDeal(
            userToken: $userToken
            editManualDeal: $editDeal
        ) {
            ...dealResponse
        }
    }
    ${dealResponseFragment}
`;
