import React, { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
import { ThemeProvider } from '@material-ui/core';
import { theme } from 'theme';
import { dataProvider as dataProviderFactory, ReactAdminResource } from './services/api';
import { i18nProvider } from './services/locale';
import authProvider from './services/auth/auth-provider';
import * as ads from './components/ads';
import * as banner from './components/hp-banner';
import * as bannerWithCategories from './components/hp-banner-with-categories';
import * as areas from './components/areas';
import * as winners from './components/winners';
import * as projectLogo from './components/project-logo';
import * as deals from 'components/deals';
import { Layout } from './components/layout';
import { Login } from './components/login';

const App: React.FC = () => {
  const [ dataProvider, setDataProvider ]  = useState<any>(undefined);

  useEffect(() => {
    const resolveDataProvider = async () => {
      const dataProviderInstance = await dataProviderFactory();
      setDataProvider(() => dataProviderInstance);
    };
    resolveDataProvider();
  }, []);

  if (!dataProvider) {
    return (
      <div>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        theme={theme}
        layout={Layout}
        loginPage={Login}
      >
        <Resource
          name={ReactAdminResource.Ads}
          list={ads.list}
          create={ads.create}
          edit={ads.edit}
          options={{ label: 'ads.resourceLabel' }}
        />
        <Resource
          name={ReactAdminResource.HPBanner}
          list={banner.list}
          options={{ label: 'homePageBanner.resourceLabel' }}
        />
        <Resource
          name={ReactAdminResource.HPBannerWithCategories}
          list={bannerWithCategories.list}
          options={{ label: 'homePageBanner.resourceLabel.new' }}
        />
        <Resource
          name={ReactAdminResource.Areas}
          list={areas.list}
          create={areas.create}
          edit={areas.edit}
          options={{ label: 'areas.resourceLabel' }}
        />
        <Resource
          name={ReactAdminResource.Winners}
          list={winners.list}
          create={winners.create}
          edit={winners.edit}
          options={{ label: 'winners.resourceLabel' }}
        />
        <Resource
          name={ReactAdminResource.ProjectLogoOnMap}
          list={projectLogo.list}
          create={projectLogo.create}
          edit={projectLogo.edit}
          options={{ label: 'projectLogoOnMap.resourceLabel' }}
        />
        <Resource
          name={ReactAdminResource.Deals}
          list={deals.list}
          create={deals.create}
          edit={deals.edit}
          options={{ label: 'deals.resourceLabel' }}
        />
        <Resource name={ReactAdminResource.ObjectStorageURL} />
        <Resource name={ReactAdminResource.Address} />
        <Resource name={ReactAdminResource.AddressArea} />
        <Resource name={ReactAdminResource.AddressWinners} />
      </Admin>
    </ThemeProvider>
  );
}

export default App;
