import { ApolloQueryResult } from 'apollo-client';
import { DocumentNode } from 'graphql';
import { buildQuery as buildQueryFactory } from 'ra-data-graphql-simple';
import { CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, LegacyDataProvider, UPDATE, } from 'ra-core';
import {
  GqlResource,
  ResponseByLoadType,
  ResponseByMutationType,
  UiCampaign,
  WinnerRole,
  WinningDocId,
} from './types';
import { LoadType, QUERIES_BY_LOAD_TYPE } from './queries';
import { MUTATIONS_BY_MUTATION_TYPE, MutationType } from './mutations';
import { convertToIsoString } from 'utils/date';
import INTROSPECTION_RESULTS from './introspection-results.json';
import tokenStore from 'services/auth/token-store';
import { filterByParams, DEFAULT_PER_PAGE, DEFAULT_PAGE } from 'utils/filterList';
import { formInputToNewDealInput, getWinnerIdParam, winnerFetchMapper, dealFetchMapper } from 'utils/dataMappers';
import { getAddressListBuildObj } from 'utils/buildFunctions';
import { trim } from 'lodash';
import { DEAL_EXISTS_ERROR } from 'components/deals/helpers';


export const LOGIN = 'LOGIN';

export type OperationType =
  | typeof CREATE
  | typeof GET_ONE
  | typeof GET_LIST
  | typeof GET_MANY
  | typeof UPDATE
  | typeof DELETE
  | typeof LOGIN;

type QueryBuilderFn = (type: OperationType, params: any) => any;

const BUILD_FUNCTIONS: Record<GqlResource, QueryBuilderFn> = {
  [GqlResource.HPBanner]: () => null,
  [GqlResource.ObjectStorageURL]: (type, params) => {
    switch (type) {
      case CREATE: {
        const {
          fileName,
          fileType,
          objectType,
          userToken,
        } = params;

        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.ObjectStorageUploadUrl],
          variables: {
            fileName,
            fileType,
            objectType,
            userToken,
          },
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.ObjectStorageUploadUrl]>) => res,
        };
      }
      default:
        return null;
    }
  },
  [GqlResource.Auth]: (type, params) => {
    switch (type) {
      case LOGIN: {
        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.Login],
          variables: {
            email: params.email,
            password: params.password,
          },
          parseResponse: (res: ApolloQueryResult<ResponseByMutationType[MutationType.Login]>) => {
            return res.data.passwordLoginV3;
          },
        };
      }
    }
  },
  [GqlResource.Ad]: (type, params) => {
    switch (type) {
      case CREATE: {
        const { data } = params;
        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.SaveCampaign],
          variables: {
            campaign: {
              bannerPath: data.bannerPath,
              mobileBannerPath: data.mobileBannerPath,
              externalLink: data.externalLink,
              package: data.package,
              relevantDocIds: data.relevantDocIds,
              budget: data.budget,
              status: data.status,
              startDate: data.startDate ? convertToIsoString(data.startDate) : undefined,
              endDate: data.endDate ? convertToIsoString(data.endDate) : undefined,
            }
          },
          parseResponse: (res: ApolloQueryResult<ResponseByMutationType[MutationType.SaveCampaign]>) => {
            return {
              data: {
                id: res.data.saveCampaign[0].id,
              }
            };
          },
        };
      }
      case UPDATE: {
        const { data } = params;
        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.EditCampaign],
          variables: {
            campaign: {
              id: data.id,
              bannerPath: data.bannerPath,
              mobileBannerPath: data.mobileBannerPath,
              externalLink: data.externalLink,
              package: data.package,
              relevantDocIds: data.relevantDocIds,
              budget: data.budget,
              status: data.status,
              startDate: data.startDate ? convertToIsoString(data.startDate) : undefined,
              endDate: data.endDate ? convertToIsoString(data.endDate) : undefined,
            }
          },
          parseResponse: (res: ApolloQueryResult<ResponseByMutationType[MutationType.EditCampaign]>) => {
            return {
              data: {
                id: res?.data?.editCampaign?.id,
              },
            };
          },
        };
      }
      case GET_LIST: {
        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.GetAllCampaigns],
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.GetAllCampaigns]>) => {
            const campaigns = res?.data?.getAllCampaigns;
            return {
              data: campaigns.map<UiCampaign>(campaign => ({
                ...campaign,
                relevantDocs: campaign.relevantDocIds,
                relevantDocIds: campaign.relevantDocIds?.map(doc => doc.docId),
              })),
              total: campaigns?.length,
            };
          },
        };
      }
      case GET_ONE: {
        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.GetAllCampaigns],
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.GetAllCampaigns]>) => {
            const campaign = res?.data?.getAllCampaigns?.find(rec => rec.id === params.id);
            const uiCampaign = campaign ? {
              ...campaign,
              relevantDocs: campaign.relevantDocIds,
              relevantDocIds: campaign.relevantDocIds.map(doc => doc.docId),
            } : null;

            return { data: uiCampaign };
          },
        }
      }
      case DELETE: {
        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.DeleteCampaign],
          variables: {
            id: params.id,
          },
          parseResponse: () => ({
            data: {
              id: null,
            }
          })
        };
      }
      default:
        return null;
    }
  },
  [GqlResource.Address]: (type, params) => {
    switch (type) {
      case GET_LIST: {
        return getAddressListBuildObj(params);
      }
      case GET_MANY: {
        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.GetAllCampaigns],
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.GetAllCampaigns]>) => {
            const campaigns = res?.data?.getAllCampaigns;
            const data = campaigns
              .flatMap(campaign => campaign.relevantDocIds)
              .map(relevantDoc => ({ ...relevantDoc, id: relevantDoc.docId }));

            return { data, total: data.length };
          },
        };
      }
      default:
        return null;
    }
  },
  [GqlResource.Area]: (type, params) => {
    switch (type) {
      case CREATE: {
        const { data } = params;
        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.SaveArea],
          variables: {
            area: {
              areaName: data.areaName,
              relevantDocIds: data.relevantDocIds,
            },
          },
          parseResponse: (res: ApolloQueryResult<ResponseByMutationType[MutationType.SaveArea]>) => {
            return {
              data: {
                id: res.data.saveArea.areaId,
              }
            };
          },
        };
      }
      case UPDATE: {
        const { data } = params;
        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.EditArea],
          variables: {
            area: {
              areaId: data.areaId,
              areaName: data.areaName,
              relevantDocIds: data.relevantDocIds,
            },
          },
          parseResponse: (res: ApolloQueryResult<ResponseByMutationType[MutationType.EditArea]>) => {
            return {
              data: {
                id: res?.data?.saveArea?.areaId,
              },
            };
          },
        };
      }
      case GET_LIST:
        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.GetAllAreas],
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.GetAllAreas]>) => {
            const areas = res?.data?.getAllAreas || [];
            const data = areas.map( area => ({
              ...area,
              id: area.areaId,
              relevantDocs: area.relevantDocIds,
              relevantDocIds: area.relevantDocIds.map(doc => doc.docId),
            }));
            const { data: filteredData, total } = filterByParams(data.filter(Boolean), params);

            return { data: filteredData, total };
          },
        };
      case GET_ONE: {
        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.GetAllAreas],
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.GetAllAreas]>) => {
            const area = res?.data?.getAllAreas?.find(rec => rec.areaId === +params.id);
            const areaUI = area ? {
              ...area,
              id: area.areaId,
              relevantDocs: area.relevantDocIds,
              relevantDocIds: area.relevantDocIds.map(doc => doc.docId),
            } : null;

            return { data: areaUI };
          },
        };
      }
      case DELETE:
        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.DeleteArea],
          variables: {
            areas: [{
              areaId: params.id,
            }],
          },
          parseResponse: () => ({
            data: {
              id: null,
            }
          })
        };
      default:
        return null;
    }
  },
  [GqlResource.AddressArea]: (type, params) => {
    switch (type) {
      case GET_LIST: {
        return getAddressListBuildObj(params);
      }
      case GET_MANY: {
        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.GetAllAreas],
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.GetAllAreas]>) => {
            const areas = res?.data?.getAllAreas;
            const data = areas
              .flatMap(area => area.relevantDocIds)
              .map(relevantDoc => ({ ...relevantDoc, id: relevantDoc.docId }));

            return { data, total: data.length };
          },
        };
      }
      default:
        return null;
    }
  },
  [GqlResource.Winners]: (type, params) => {
    switch (type) {
      case CREATE: {
        const { data } = params;
        const id = trim(data._id);
        const name = trim(data.name);

        let winner;

        if (data.role === WinnerRole.Office) {
          winner = {
            role: WinnerRole.Office,
            officeId: id,
            officeName: name,
            winningDocId: data.winningDocId,
          };
        } else {
          winner = {
            role: WinnerRole.Agent,
            agentId: id,
            agentName: name,
            winningDocId: data.winningDocId,
          };
        }

        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.SaveWinner],
          variables: { winner },
          parseResponse: (res: ApolloQueryResult<ResponseByMutationType[MutationType.SaveWinner]>) => {
            return {
              data: {
                id: res?.data?.saveWinner?.agentId || res?.data?.saveWinner?.officeId,
              },
            };
          },
        };
      }
      case UPDATE: {
        const { data } = params;
        let winner;
        const winningDocId = data.winningDocId.map((winningDocId: WinningDocId) => {
          const { category, docId, exclusiveListingCount, soldCount } = winningDocId;
          return ({ category, docId, exclusiveListingCount, soldCount })
        });
        if (data.role === WinnerRole.Office) {
          winner = {
            officeId: data.officeId || data._id,
            role: WinnerRole.Office,
            officeName: data.name,
            winningDocId,
          };
        } else {
          winner = {
            agentId: data.agentId || data._id,
            role: WinnerRole.Agent,
            agentName: data.name,
            winningDocId,
          };
        }

        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.SaveWinner],
          variables: { winner },
          parseResponse: (res: ApolloQueryResult<ResponseByMutationType[MutationType.SaveWinner]>) => {
            return {
              data: {
                id: res?.data?.saveWinner?.agentId || res?.data?.saveWinner?.officeId,
              },
            };
          },
        };
      }
      case GET_LIST:
        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.GetAllWinners],
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.GetAllWinners]>) => {
            const winners = res?.data?.getAllWinners || [];
            const data = winners.map(winnerFetchMapper);
            const { data: filteredData, total } = filterByParams(data.filter(Boolean), params);

            return { data: filteredData, total };
          },
        };
      case GET_ONE:
        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.GetAllWinners],
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.GetAllWinners]>) => {
            const { officeId, agentId, role } = getWinnerIdParam(params.id);
            const id = role === WinnerRole.Agent ? agentId : officeId;
            const winner = res?.data?.getAllWinners?.find(rec => {
              const winnerId = rec.role === WinnerRole.Agent ? rec.agentId : rec.officeId;
              return winnerId === id;
            });
            const data = winner ? winnerFetchMapper(winner) : null;

            return { data };
          },
        };
      default:
        return null;
    }
  },
  [GqlResource.AddressWinners]: (type, params) => {
    switch (type) {
      case GET_LIST:
        return getAddressListBuildObj(params);
      case GET_MANY: {
        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.GetAllWinners],
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.GetAllWinners]>) => {
            const winners = res?.data?.getAllWinners;
            const data = winners
              .flatMap(winner => winner.winningDocId)
              .map(winningDoc => winningDoc ? ({ ...winningDoc, id: winningDoc.docId }) : null);

            return { data, total: data.length };
          },
        }
      }
      default:
        return null;
    }
  },
  [GqlResource.Deals]: (type, params) => {
    switch (type) {
      case CREATE: {
        const { data } = params;

        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.NewDeal],
          variables: {
            newManualDeal: formInputToNewDealInput(data),
            forceCreate: false,
          },
          parseResponse: (res: ApolloQueryResult<ResponseByMutationType[MutationType.NewDeal]>) => {
            if (res.data.newManualDeal.__typename === DEAL_EXISTS_ERROR) {
              return ({
                data: {
                  ...res.data.newManualDeal,
                  id: 0,
                },
              });
            }

            return ({
              data: res.data.newManualDeal.deal,
            })
          },
        };
      }
      case GET_LIST: {
        const hasFilter = Boolean(trim(params?.filter?.q));
        const limit = params?.pagination?.perPage || DEFAULT_PER_PAGE;
        const page = params?.pagination?.page || DEFAULT_PAGE;
        const offset = (page - 1) * limit;
        const field = params?.sort?.field || 'dealDate';
        const order = params?.sort?.order || 'DESC';
        const asc = order === 'ASC';

        const variables = hasFilter ? {
          limit: 10000,
          offset: 0,
        } : {
          limit,
          offset,
          ...(params?.sort ? ({
            sort: { field, asc }
          }) : null)
        };

        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.Deals],
          fetchPolicy: 'no-cache',
          variables,
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.Deals]>) => {
            const data = (res?.data?.getManualDeals?.deals || []).map(dealFetchMapper);
            const hasFilter = Boolean(trim(params?.filter?.q));
            if (!hasFilter){
              return { data, total: res?.data?.getManualDeals?.totalDeals };
            }
            const dateFilterFn = (val: string, search: string) => {
              const dateVal = new Date(val);
              const year = dateVal.getFullYear();
              const month = dateVal.getMonth() + 1;
              const day = dateVal.getDate();

              const yearStr = `${year}`;
              const monthStr = `${month < 10 ? '0' : ''}${month}`;
              const dayStr = `${day < 10 ? '0' : ''}${day}`;

              const parsedSearch = search.replace(/\D/g, '')

              const options = new Set([
                dayStr,
                monthStr,
                yearStr,
                `${dayStr}${monthStr}`,
                `${monthStr}${yearStr}`,
                `${dayStr}${monthStr}${yearStr}`,
              ]);

              return options.has(parsedSearch);
            };
            const searchBy = [
              { name: 'dealDate', filterFn: dateFilterFn },
              { name: 'uploadDate', filterFn: dateFilterFn },
              { name: 'lastUpdated', filterFn: dateFilterFn },
              { name: 'gushHelka' },
              { name: 'settlement' },
              { name: 'unitFunctioning' },
              { name: 'soldId' },
              { name: 'status' },
              { name: 'userMail'},
            ];

            const { data: filteredData, total } = filterByParams(data.filter(Boolean), params, { searchBy });

            return { data: filteredData, total };
          },
        };
      }
      case GET_ONE: {
        const { id } = params;

        return {
          query: QUERIES_BY_LOAD_TYPE[LoadType.Deal],
          variables: {
            dealId: id,
          },
          parseResponse: (res: ApolloQueryResult<ResponseByLoadType[LoadType.Deal]>) => {
            const data = dealFetchMapper(res.data.getManualDeal.deal);
            return { data };
          },
        }
      }
      case DELETE: {
        const { id } = params;
        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.DeleteDeals],
          variables: {
            deleteDeals: [ id ],
          },
          parseResponse: () => ({
            data: {
              id: null,
            },
          })
        };
      }
      case UPDATE: {
        const { id, data } = params;

        return {
          query: MUTATIONS_BY_MUTATION_TYPE[MutationType.EditDeal],
          variables: {
            editManualDeal: { id, ...formInputToNewDealInput(data)},
          },
          parseResponse: (res: ApolloQueryResult<ResponseByMutationType[MutationType.EditDeal]>) => {
            const responseData = res.data.editManualDeal.deal ? res.data.editManualDeal.deal : res.data.editManualDeal;
            return {
              data: {
                ...responseData,
                id: 1,
              }
            }
          },
        }
      }

      default:
        return null;
    }
  },
};

export const buildQuery = (): LegacyDataProvider => {
  const defaultBuildQuery = buildQueryFactory(INTROSPECTION_RESULTS);

  return (type, resource, params) => {
    const resourceBuildFns = BUILD_FUNCTIONS[resource as GqlResource];
    const customQuery = resourceBuildFns ? resourceBuildFns(type as OperationType, params) : null;

    if (customQuery) {
      return enrichGqlVariables(customQuery);
    }

    return defaultBuildQuery(type, resource, params);
  };
};

const enrichGqlVariables = (query: any) => {
  if (operationNeedsToken(query.query) && !(query.variables && query.variables.userToken)) {
    return {
      ...query,
      variables: {
        ...query.variables,
        userToken: tokenStore.get(),
      }
    };
  }
  return query;
}

const operationNeedsToken = (document: DocumentNode): boolean => {
  switch (document.kind) {
    case 'Document':
      return document.definitions.some(def => (
        def.kind === 'OperationDefinition'
        && def.variableDefinitions
        && def.variableDefinitions.some(v => v.variable.name.value === 'userToken')
      ));
    default:
      return false;
  }
};
