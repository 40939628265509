import gql from 'graphql-tag';
import { dealResponse as dealResponseFragment } from 'services/api/fragments/dealResponse';


export default gql`
    query getManualDeal (
        $userToken: UserToken!
        $dealId: String!
    ) {
        getManualDeal(
            userToken: $userToken
            dealId: $dealId
        ) {
            ...dealResponse
        }
    }
    ${dealResponseFragment}
`;
