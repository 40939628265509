import { INewDealInput, UiWinner, Winner, WinnerRole, IDeal } from 'services/api/types';
import { parseInt, trim, isNaN } from 'lodash';

const genWinnerId = (winner: Winner): string => {
  let id;
  switch (winner.role) {
    case WinnerRole.Office:
      id = winner.officeId;
      break;
    case WinnerRole.Agent:
      id = winner.agentId;
      break;
    default:
      id = null;
  }
  return `${winner.role}__${id}`;
};

export const getWinnerIdParam = (str: string) => {
  const officePrefix = `${WinnerRole.Office}__`;
  const agentPrefix = `${WinnerRole.Agent}__`;
  const isOffice = str.indexOf(officePrefix) > -1;

  if (isOffice) {
    return ({
      officeId: str.replace(officePrefix, ''),
      role: WinnerRole.Office,
    });
  }

  return ({
    agentId: str.replace(agentPrefix, ''),
    role: WinnerRole.Agent,
  });
};

export const winnerFetchMapper = (winner: Winner): UiWinner | null => {
  switch(winner.role) {
    case WinnerRole.Office:
      return ({
        id: genWinnerId(winner),
        _id: winner.officeId,
        role: WinnerRole.Office,
        name: winner.officeName,
        winningDocId: winner.winningDocId,
      });

    case WinnerRole.Agent:
      return ({
        id: genWinnerId(winner),
        _id: winner.agentId,
        name: winner.agentName,
        role: WinnerRole.Agent,
        winningDocId: winner.winningDocId,
      });

    default:
      return null;
  }
};

export const formInputToNewDealInput = (input: any): INewDealInput => {
  const {
    gushHelka,
    settlement,
    street,
    houseNumber,
    entrance,
    apartmentNumber,
    price,
    dealDate,
    grossArea,
    netArea,
    rooms,
    unitFunctioning,
    propertyFloorNumber,
    totalFloors,
    constructionYear,
    partSold,
  } = input;

  const parsedApartmentNumber = parseInt(apartmentNumber);
  const parsedPrice = parseInt(price);
  const parsedRooms = parseFloat(rooms);
  const parsedPropertyFloor = parseInt(propertyFloorNumber);
  const parsedTotalFloors = parseInt(totalFloors);
  const parsedConstructionYear = parseInt(constructionYear);
  const parsedGrossArea = parseInt(grossArea);
  const parsedNetArea = parseInt(netArea);
  const parsedPartSold = parseFloat(partSold);

  const separator = '-';
  const [ day, month, year ] = trim(dealDate).split('/');
  const parsedDealDate = new Date(`${year}${separator}${month}${separator}${day}T00:00:00Z`);

  return ({
    gushHelka,
    street: trim(street),
    settlement: trim(settlement),
    houseNumber: parseInt(houseNumber, 10),
    entrance,
    apartmentNumber: isNaN(parsedApartmentNumber) ? null : parsedApartmentNumber,
    price: parsedPrice,
    dealDate: parsedDealDate.toISOString(),
    grossArea: parsedGrossArea,
    netArea: parsedNetArea,
    unitFunctioning,
    partSold: parsedPartSold,
    rooms: parsedRooms,
    propertyFloorNumber: parsedPropertyFloor,
    totalFloors: parsedTotalFloors,
    constructionYear: parsedConstructionYear,
  });
};

export const dealFetchMapper = (item: IDeal) => {
  const separator = '/';
  let dealDate;
  const date = new Date(item.dealDate);

  if (isNaN(date)) {
    dealDate = '';
  }
  else {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    dealDate = `${day < 10 ? '0' : ''}${day}${separator}${month < 10 ? '0' : ''}${month}${separator}${year}`;
  }

  return ({
    ...item,
    apartmentNumber: item.apartmentNumber === null ? undefined : item.apartmentNumber,
    dealDate,
  })
};
