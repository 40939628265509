import { Config } from './';

const config: Config = {
  apiEndpoint: `https://${process.env.REACT_APP_URL_NAME || 'www'}.madlan-internal.com/api2`,
  newApiEndpoint: `https://${process.env.REACT_APP_URL_NAME || 'www'}.madlan-internal.com/api3`,
  s3Endpoint: 'https://s3-eu-west-1.amazonaws.com/media.madlan-internal.com',
  HPBannerPath: 'home-page-banner',
  HPBannerWithCategoriesPath: 'home-page-banner-with-categories',
  projectLogoPath: 'project-logo',
  cdnUrl: 'https://images2.madlan-internal.com',
  perimeterXAppId: 'PXNTnT8B49',
};

export default config;
