import gql from 'graphql-tag';

export default gql`
  query getAllAreas (
    $userToken: String!
  ) {
    getAllAreas (
      userToken: $userToken
    ) {
      areaId
      areaName
      relevantDocIds {
        docId
        type
        name
      }
    }
  }
`;
