import polyglotI18nProvider from 'ra-i18n-polyglot';
import { LocaleCode } from './types';
import englishMessages from './en-US';

const messages = {
  [LocaleCode.EnglishUS]: englishMessages,
};

const i18nProvider = polyglotI18nProvider(
  locale => messages[locale as LocaleCode] ? messages[locale as LocaleCode] : messages[LocaleCode.EnglishUS],
  LocaleCode.EnglishUS,
);

export default i18nProvider;
