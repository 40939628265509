import { DocumentNode } from 'graphql';
import saveCampaignMutation from './saveCampaignMutation';
import editCampaignMutation from './editCampaignMutation';
import deleteCampaignMutation from './deleteCampaignMutation';
import loginMutation from './loginMutation';
import saveAriaMutation from './saveAriaMutation';
import deleteAreaMutation from './deleteAreaMutation';
import saveWinnerMutation from './saveWinnerMutation';
import newDealMutation from './newDealMutation';
import deleteDealsMutation from './deleteDealsMutation';
import editDealMutation from './editDealMutation';


export enum MutationType {
  SaveCampaign = 'SaveCampaign',
  EditCampaign = 'EditCampaign',
  DeleteCampaign = 'DeleteCampaign',
  Login = 'Login',
  SaveArea = 'SaveArea',
  EditArea = 'EditArea',
  DeleteArea = 'DeleteArea',
  SaveWinner = 'SaveWinner',
  NewDeal = 'NewDeal',
  DeleteDeals = 'DeleteDeals',
  EditDeal = 'EditDeal',
}

export const MUTATIONS_BY_MUTATION_TYPE: Record<MutationType, DocumentNode> = {
  [MutationType.SaveCampaign]: saveCampaignMutation,
  [MutationType.EditCampaign]: editCampaignMutation,
  [MutationType.DeleteCampaign]: deleteCampaignMutation,
  [MutationType.Login]: loginMutation,
  [MutationType.SaveArea]: saveAriaMutation,
  [MutationType.EditArea]: saveAriaMutation,
  [MutationType.DeleteArea]: deleteAreaMutation,
  [MutationType.SaveWinner]: saveWinnerMutation,
  [MutationType.NewDeal]: newDealMutation,
  [MutationType.DeleteDeals]: deleteDealsMutation,
  [MutationType.EditDeal]: editDealMutation,
};
