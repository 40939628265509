import gql from 'graphql-tag';

export default gql`
  mutation saveArea (
    $area: inputArea
    $userToken: String!
  ){
    saveArea (
      area: $area
      userToken: $userToken
    ) {
      areaId
    }
  }
`;
