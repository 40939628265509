import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
} from 'react-admin';
import WinnerFormFields from './WinnerFormFields';


const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomToolbar = (props: any) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);

const WinnerEdit: React.FC = (props: unknown) => {
  return (
    <Edit
      {...props}
      undoable={false}
    >
      <SimpleForm autoComplete="off" toolbar={<CustomToolbar />}>
        <WinnerFormFields isEdit />
      </SimpleForm>
    </Edit>
  );
};

export default WinnerEdit;
