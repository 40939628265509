import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Filter,
  ListProps,
  NumberField,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import DateFieldCustom from 'components/date-field';

const useStyles = makeStyles(_ => ({
  headerClass: {
    whiteSpace: 'nowrap',
  },
  bodyClass: {
    whiteSpace: 'nowrap',
  },
}));
const PostFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const DealsList: React.FC<ListProps> = props => {
  const classes = useStyles();

  return (
    <List
      filters={<PostFilter />}
      {...props}
      sort={{ field: 'lastUpdated', order: 'DESC' }}
      perPage={25}
    >
      <Datagrid optimized>
        <DateFieldCustom
          label="deals.input.uploadDate"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          source="uploadDate"
          sortable
        />
        <TextField
          source="gushHelka"
          label="deals.input.gushHelka"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable
        />
        <TextField
          source="settlement"
          label="deals.input.settlement"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable
        />
        <TextField
          source="street"
          label="deals.input.street"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable
        />
        <TextField
          source="houseNumber"
          label="deals.input.houseNumber"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable
        />
        <NumberField
          source="price"
          label="deals.input.price"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable={false}
        />
        <TextField
          source="dealDate"
          label="deals.input.dealDate"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable
        />
        <TextField
          source="grossArea"
          label="deals.input.grossArea"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable
        />
        <TextField
          source="netArea"
          label="deals.input.netArea"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable
        />
        <TextField
          source="soldId"
          label="deals.input.soldId"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable={false}
        />
        <DateFieldCustom
          source="lastUpdated"
          label="deals.input.lastUpdated"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable
        />
        <TextField
          source="status"
          label="deals.input.status"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable
        />
        <TextField
          source="userMail"
          label="deals.input.user"
          headerClassName={classes.headerClass}
          className={classes.bodyClass}
          sortable
        />
        <EditButton />
        <DeleteWithConfirmButton undoable={false} />
      </Datagrid>
    </List>
  );
};

export default DealsList;
