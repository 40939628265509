import React from 'react'
import classnames from 'classnames';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import ConfirmIcon from '@material-ui/icons/CheckCircle';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  iconPaddingStyle: {
    paddingRight: '0.5em',
  },
  confirmPrimary: {
    color: '#03a9f4',
  },
});

interface DealAlreadyExistsDialogProps {
  onClose: () => void;
}

const DealAlreadyExistsDialog: React.FC<DealAlreadyExistsDialogProps> = ({onClose}) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {translate('deals.create.alreadyExists.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ whiteSpace: 'pre' }}>
            {translate('deals.create.alreadyExists.content')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
          <Button
            onClick={onClose}
            className={classnames('ra-confirm', {
              [classes.confirmPrimary]: true,
            })}
            autoFocus
          >
            <ConfirmIcon className={classes.iconPaddingStyle} />
            {translate('deals.create.alreadyExists.ok')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default DealAlreadyExistsDialog;
