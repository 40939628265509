import * as React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Logo from './Logo';

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(1)
  },
  spacer: {
    flex: 1,
  },
}));

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  return (
    <AppBar
      {...props}
      elevation={1}
    >
      <Logo />
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
      >
        | Backoffice
      </Typography>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
