import { Button } from '@material-ui/core'
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { get } from 'lodash';
import React from 'react';
import { useTranslate } from 'react-admin';
import { ISlideWithCategories } from 'services/api/types';

interface SlidersList {
  slides: ISlideWithCategories[];
  onEdit: React.Dispatch<React.SetStateAction<string | null>>
}

export const SlidersList: React.FC<SlidersList> = ({ slides, onEdit }) => {
  const translate = useTranslate();
  const editText = translate('homePageBanner.slide.modal.edit');

  const columns: GridColumns = [
    {
      field: 'marketingText',
      headerName: 'marketing text',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'status',
      flex: 1,
    },
    {
      field: 'aside.link',
      headerName: 'aside link',
      flex: 1,
      renderCell: (params) => {
        const href = get(params, 'row.aside.link');
        if (href) return <a target="_blank" rel="noopener noreferrer" href={href}>{href}</a>
        return <span />
      },
    },
    {
      field: 'navigation',
      headerName: 'navigation',
      flex: 1,
      renderCell: (params) => {
        const href = get(params, 'row.navigation.link');
        const text = get(params, 'row.navigation.text');
        if (href) return <a target="_blank" rel="noopener noreferrer" href={href}>{text}</a>
        return <span>{text}</span>
      },
    },
    {
      field: 'edit',
      headerName: 'edit',
      renderCell: (params) => {
        return <Button onClick={() => onEdit(params.row.id)} variant="contained" color="primary">{editText}</Button>
      },
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={slides}
      />
    </div>
  );
};