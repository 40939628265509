import React from 'react';
import { FunctionField, FunctionFieldProps } from 'react-admin';
interface DateFieldProps extends Omit<FunctionFieldProps, 'render'> {}

const SEPARATOR = '/';

const DateField: React.FC<DateFieldProps> = (props) => (
  <FunctionField
    {...props}
    render={(record: any, source: string | undefined) => {
      if (!source || !record[source]) return null;
      const date = new Date(record[source]);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return (
        <>{`${day < 10 ? '0' : ''}${day}${SEPARATOR}${month < 10 ? '0' : ''}${month}${SEPARATOR}${year}`}</>
      );
    }}
  />
);

export default DateField;
